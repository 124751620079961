<!-- 8-S -->
<template>
    <v-container fluid>
      <v-row class="mb-1">
        <v-spacer></v-spacer>
        <v-col cols="3"></v-col>
        <v-col cols="3" relative>

        
        <h3>용기 </h3>
        <h4 class="secondary--text">[ {{ currPage +1 }} / 총{{ totalPage }} ]</h4>
  
        </v-col>
        <v-col cols="6" class="error--text text-subtitle-2">
            <v-icon
              color="error"
              dark
            >
              mdi-copyright
            </v-icon>
          <span >무단으로 작품을 복사하거나 배포하는 저작권 침해 행위는 법적 처분을 받습니다.</span>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
        <template>
            <v-container fluid>
                <v-row>
                    <v-col cols='8'>
                        
                        <tui-image-editor ref="tuiImageEditor" :include-ui="useDefaultUI" :options="options" style="margin: 0 auto; height: 800px;" ></tui-image-editor>
                    </v-col>
                    <v-col cols='4' class="d-flex align-end">
                        <v-card class="mb-12" >
                            <v-card-text>
                                <h3>수익 요청</h3>
                                
                                    <div class="text-subtitle-2 my-2 secondary--text">번역 파트너님께서 번역한 콘텐츠에 대한 수익 배분을 원작자님께 요청하셨습니다. 
                                    </div>
                                    
                                <v-radio-group
                                v-model="proposed"
                                row
                                >
                                <v-radio
                                    label="50%"
                                    value="1"
                                ></v-radio>
                                </v-radio-group>
                            </v-card-text>
                            <v-card-text>
                                <h3>수익 조율</h3>
                                
                                    <div class="text-subtitle-2 my-2 error--text">*수익배분에 대해 수정하지 않고 승인을 하면 번역가와  6개월 동안 파트너십 해지가 불가합니다.
                                    </div>
                                    
                                <v-radio-group
                                v-model="proposing"
                                row
                                >
                                <v-radio v-for="(item, index) in proposingList" :key="index" :label="item.label" :value="item.value"></v-radio>
                                <v-radio value="q">
                                    <template v-slot:label>
                                        <div style="width:50px">
                                            
                                            <v-text-field
                                            label="기타"
                                            ></v-text-field>
                                        </div>
                                    </template>
                                </v-radio>
                                </v-radio-group>
                            </v-card-text>
                            <v-card-actions class="d-flex justify-end">
                                <v-btn >제안하기</v-btn>
                              
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>
                
            </v-container>

        </template>

        <template>

          <v-tooltip
          v-model="firstPageToolTip"
          absolute
          bottom
          right
          content-class="custom-tooltip"
          attach=".tui-image-editor-controls"
          >
          <span>첫 페이지 입니다.</span>
        </v-tooltip>
      </template>
        <template>

          <v-tooltip
          v-model="lastPageToolTip"
          absolute
          bottom
          right
          content-class="custom-tooltip"
          attach=".tui-image-editor-controls"
          >
          <span>마지막 페이지 입니다.</span>
        </v-tooltip>
      </template>
      
      <template>
        <div class="d-flex justify-end my-2">
            <v-btn icon class="mt-1 mr-3"  router :to="'/'">
            <v-avatar router :to="'/'">
                <v-img src="https://cdn.vuetifyjs.com/images/john.jpg"></v-img>
            </v-avatar>

            </v-btn>
            <div>
            <template>
               

            <v-dialog
                v-model="prdialog"
                max-width="300px"
            >
                
            <template v-slot:activator="{ on, attrs }">
                <v-btn class="ma-1" 
                v-bind="attrs"
                v-on="on"
                >PR</v-btn>
            </template>

            <v-card
            >
                <v-card-title  class="d-flex justify-space-between">
                    <div >
                        포트폴리오
                    </div>
                    <v-btn text icon @click="prdialog = false">
                        <v-icon>
                            mdi-close
                        </v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-select
                        :items="[]"
                        v-model="value"
                        label="링크"
                    ></v-select>
                    <v-select
                        :items="[]"
                        v-model="value"
                        label="파일"
                    ></v-select>
                </v-card-text>
            </v-card>
            </v-dialog>
        </template>
    </div>
          <v-btn class="ma-1" @click.stop="tdialog = true">언어 <v-icon class="ml-1">mdi-chevron-down</v-icon></v-btn>
          <translate-dialog v-model="tdialog"></translate-dialog>
          <v-btn class="ma-1" @click="commDialog=true" >소통하기</v-btn>
          <v-dialog
            v-model="commDialog"
            max-width="500px"
          >
            <v-card>
              <v-card-title >
                소통하기
              </v-card-title>
                <v-card-text>
                  
                  
                  <v-list >
                    <!-- <v-subheader>REPORTS</v-subheader> -->
                    <v-list-item>
                      
                      <v-list-item-icon >
                  <v-icon>mdi-email-outline</v-icon>
                </v-list-item-icon>
                
                <v-list-item-content>
                  <v-list-item-title>rahanart@gmail.com</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                
                <v-list-item-icon >
                  <v-icon>mdi-web</v-icon>
                </v-list-item-icon>
                
                <v-list-item-content>
                  <v-list-item-title>www.grape.com/@rahanart</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
            </v-card>
          </v-dialog>
          <v-btn class="ma-1" color="primary" @click="confirmDialog=true">승인</v-btn>
          <v-dialog
            v-model="confirmDialog"
            max-width="750px"
          >
          <v-card>
            <v-card-title class="pt-10">
            </v-card-title>
            <v-card-text class="text-center">
              <p>승인버튼을 누르면 6개월간 계약 해지가 불가 합니다.</p>
              <p>본 번역가와 계약하시겠습니까?</p>
              <p class="error--text text-caption">* 계약 후 번역된 콘텐츠에 대한 광고 시청 및 조회수에 따른 수익금이 자동으로 번역가 채널로 나뉘어 입금됩니다.</p>
            </v-card-text>
              <v-container fluid>
                <v-row>
                  <v-col cols="6" class="pa-0">
                    <v-btn block @click="confirmDialog=false" class="py-5">취소</v-btn>
                  </v-col>
                  <v-col cols="6" class="pa-0">
                    <v-btn block color="primary" @click="confirmedDialog=true; confirmDialog=false" class="py-5">승인</v-btn>
                  </v-col>
                </v-row>
                
              </v-container>
          </v-card>            
          </v-dialog>
          <v-dialog
            v-model="confirmedDialog"
            max-width="400px"
            >
            <v-card class="text-center">
              <v-card-title class="pt-10" >
                <div style="width: 100%;">

                  계약 완료
                </div>
              </v-card-title>
              <v-card-text class="pb-10">
                창작 파트너와 함께 무궁한 발전을 기원합니다.
              </v-card-text>
              <v-btn color="primary" block @click="confirmedDialog=false" class="py-5">확인</v-btn>
            </v-card>
          </v-dialog>
        </div>
      </template>

    </v-container>
</template>

<script>
import 'tui-color-picker/dist/tui-color-picker.css';
import 'tui-image-editor/dist/tui-image-editor.css';
import { ImageEditor } from '@toast-ui/vue-image-editor';
import TranslateDialog from '@/components/dialogs/TranslateDialog.vue';


export default {
  components: {
    'tui-image-editor': ImageEditor,
    TranslateDialog,
  },
  data() {
    return {
      confirmedDialog:false,
      confirmDialog:false,
      commDialog:false,
      proposed: '1',
      proposing: '1',
      proposingList:[
        {
            label: '10%',
            value: '1',
          },
          {
            label: '20%',
            value: '2',
          },
          {
            label: '30%',
            value: '3',
          },
          {
            label: '40%',
            value: '4',
          },
           {
            label: '50%',
            value: '5',
          },
           
      ],
      prdialog:false,
      tdialog:false,
      currPage: 2,
      totalPage: 7,
      firstPageToolTip : false,
      lastPageToolTip : false,
      useDefaultUI: true,
      options: {
    includeUI: {
        loadImage: {
          path: require('@/assets/brave/brave_3.jpg'),
          name: 'SampleImage'
        },
        // theme: this.$vuetify.theme.themes.dark, // or whiteTheme
        menu: ['draw', 'shape','text'],
        initMenu: 'text',
        uiSize: {
            width: '100%',
            height: '1000px'
        },
        menuBarPosition: 'top'
        },
        cssMaxWidth: 700,
        cssMaxHeight: 500,
        // selectionStyle: {
        //   cornerSize: 20,
        //   rotatingPointOffset: 70
        // },
        usageStatistics: false,
  
      },
    };
  },
  mounted() {
    const requireImage = require.context('@/assets/brave/', false, /\.jpg$/);
    // let currPage = 2;
    let editorInstance = this.$refs.tuiImageEditor.editorInstance;
    let imgData = ['brave_1.jpg','brave_2.jpg','brave_3.jpg','brave_4.jpg','brave_5.jpg','brave_6.jpg','brave_7.jpg',];

    // console.log(Vue.compile("<v-btn>tt</v-btn>"))

    // document.querySelector(".tui-image-editor-menu-text .tui-image-editor-submenu-item").insertAdjacentElement("afterbegin",testel)
    // editorInstance.loadImageFromURL('https://picsum.photos/200/300', 'SampleImage').then(result => {
    //   console.log('old : ' + result.oldWidth + ', ' + result.oldHeight);
    //   console.log('new : ' + result.newWidth + ', ' + result.newHeight);
    // })

  //Font select list
      //--------------------------------------
      //Any installed web font from Google will work: https://fonts.google.com/
      let fontArray = ["Noto Sans","Arial", "Arial Black", "Caveat", "Comic Sans MS", "Courier New","Georgia1","Impact","Lobster Two", "Lucida Console","Luckiest Guy", "Open Sans","Pacifico", "Palatino Linotype","Press Start 2P", "Roboto", "Tahoma", "Tangerine", "Times New Roman","Tourney","Ultra", "Verdana","Symbol","Webdings","Wingdings","Noto Sans KR","Noto Serif KR","Jua"];

      let fontSelectHTML = '<select #fontselect default="Noto Sans" class="form-select font-selector" style="color: #e9e9e9;background: #1f1f1f; padding:0 5px; border-radius:5px">';
      for (let i = 0; i < fontArray.length; i++) {
        let selected = '';
        if(i == 0){
          selected = 'selected';
        }
        fontSelectHTML += '<option style="font-family:'+fontArray[i]+';" value="'+fontArray[i]+'" '+selected+'>'+fontArray[i]+'</option>';
      }
      fontSelectHTML +=  '</select>';

      let textMenuAlign = document.querySelector('.tui-image-editor-menu-text .tie-text-align-button');
      textMenuAlign.insertAdjacentHTML('afterbegin', fontSelectHTML);

      document.querySelector('.font-selector').addEventListener('change', () =>
        this.TUI_updateFontOnText(document.querySelector('.font-selector').value));
      //-------------------------------------

    //ON TUI objectActivated
    //   Keep track of active/selected item
    editorInstance.on('objectActivated', props => {
      this.TUI_selectedItem = props;
      this.TUI_updateFontSelected(props);
      console.log('TUI_selectedItem', props);
    });

     /********************************
   * Update font familty on text layer
   *******************************/
  this.TUI_updateFontOnText=(font) =>{
    console.log("TUI_updateFontOnText", font, this.TUI_selectedItem.id);

    if(font){
      this.TUI_selectedFont = font;
    }

    if(font && this.TUI_selectedItem){
      editorInstance.changeTextStyle(this.TUI_selectedItem.id, {
        fontFamily: font
      });
    }
  }


    /********************************
     * Update font selected with the fontfamily of the selected layer
     *******************************/
    this.TUI_updateFontSelected = (layer)=> {
      console.log("TUI_updateFontSelected", layer);

      if(layer.fontFamily){
        document.querySelector('.font-selector').value = layer.fontFamily;
        this.TUI_selectedFont = layer.fontFamily;
      }
    }

    document.querySelectorAll(".tui-image-editor-controls-buttons>*").forEach((el)=>{el.style.display="none"})
    document.querySelector(".tui-image-editor-controls-buttons").insertAdjacentHTML("afterbegin",`
    <button class="tui-image-editor-prev-btn" style="background-color: #5c2e91;border: 1px solid #5c2e91;color: #fff;font-family: 'Roboto', sans-serif;font-size: 12px; margin-right:5px">
                이전 페이지
    </button><button class="tui-image-editor-next-btn" style="background-color: #5c2e91;border: 1px solid #5c2e91;color: #fff;font-family: 'Roboto', sans-serif;font-size: 12px">
        다음 페이지
    </button>
            `)

    document.querySelector(".tui-image-editor-prev-btn").addEventListener("click",()=>{
      if (this.currPage==0) {
        this.firstPageToolTip = true;
        setTimeout(() => {
          this.firstPageToolTip = false;
        }, 1000);
      }
      this.currPage = Math.max (0,this.currPage-1)
      console.log("prev : " + this.currPage)
      console.log(editorInstance.loadImageFromURL)
      console.log(imgData[0])
      console.log(this.firstPageToolTip)
      // let s= '@/assets/brave/brave_2.jpg'
      // editorInstance.loadImageFromURL(require('@/assets/brave/brave_2.jpg'),"d")
      // editorInstance.loadImageFromURL(require(s),"d")
      
      // const imageName = 'brave_2.jpg';

      editorInstance.loadImageFromURL(requireImage(`./${imgData[this.currPage]}`), "d");

       
    })
    document.querySelector(".tui-image-editor-next-btn").addEventListener("click",()=>{
      if (this.currPage==imgData.length-1) {
        this.lastPageToolTip = true;
        setTimeout(() => {
          this.lastPageToolTip = false;
        }, 1000);
      }
      this.currPage = Math.min(imgData.length-1,this.currPage+1)
      
      editorInstance.loadImageFromURL(requireImage(`./${imgData[this.currPage]}`), "d");
    })
  }
};

</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Jua&family=Noto+Sans+KR:wght@100..900&family=Noto+Serif+KR:wght@200..900&display=swap');

.tui-image-editor-container .tui-image-editor-header-logo {
  display: none !important;
}
/* .v-tooltip--attached {
  display: inline;
}
.v-tooltip__content {
  background: rgba(97, 97, 97, 0.9);
  color: #FFFFFF;
  border-radius: 4px;
  font-size: 14px;
  line-height: 22px;
  display: inline-block;
  padding: 5px 16px;
  position: absolute;
  text-transform: initial;
  width: auto;
  opacity: 0;
  pointer-events: none;
}
.v-tooltip__content.menuable__content__active {
  opacity: 0.9;
}
.v-tooltip__content--fixed {
  position: fixed;
}
.v-tooltip__content[class*=-active] {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}
.v-tooltip__content[class*=enter-active] {
  transition-duration: 150ms;
}
.v-tooltip__content[class*=leave-active] {
  transition-duration: 75ms;
} */



</style>

<style scoped>

div.custom-tooltip {
  background: rgba(97, 97, 97, 0.9);
  color: #FFFFFF;
  border-radius: 4px;
  font-size: 14px;
  line-height: 22px;
  display: inline-block;
  padding: 5px 16px;
  /* position: absolute; */
  text-transform: initial;
  width: auto;
  pointer-events: none;
  z-index: 999;
}
</style>