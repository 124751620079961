<template>
    <v-container>
      <v-row>
        <v-col
        class="d-flex justify-end"
        >      
        <span>
          <v-btn :disabled="currPage <= 1" @click="currPage--">❮</v-btn>
  
          {{ currPage }} / {{ pageCount }}
  
          <v-btn :disabled="currPage >= pageCount " @click="currPage++">❯</v-btn>
        </span>
        </v-col>
      </v-row>
      <v-row>
        <!-- TODO: v-on:resize -->
        <v-col
        cols="6"
        class="pa-0"
        >
        <vue-pdf-embed 
        ref="pdfRef"
        :source="source1" 
        :page="currPage" 
        :annotationLayer="true"
        @rendered="handleDocumentRender"
        @internal-link-clicked="handleInternalLink"
        />
  
        </v-col>
        <v-col
        cols="6"
        class="pa-0"
        >
        <template v-if="currPage<pageCount">
  
          <vue-pdf-embed 
          :source="source1" 
          :page="currPage+1" 
          @internal-link-clicked="handleInternalLink"
          />
        </template>
  
        </v-col>
      </v-row>
      
      <!-- <div>
        <h1>File</h1> -->
        
        <!-- <h1>Base64</h1>
        <vue-pdf-embed :source="source2" /> -->
      <!-- </div> -->
       
      <v-dialog
        v-model="dialog"
        max-width="640px"
        eager
      >
       <v-card>
        <v-card-title primary-title>
          <div>
            <!-- <h3 class="headline mb-0">headline</h3>
            <div>description</div> -->
          </div>
        </v-card-title>
        <v-card-text class="pa-0 ma-0">
          <!-- TODO: skelleton loader -->
          <iframe ref="player" id="player" type="text/html" width="640" height="360"
            frameborder="0"></iframe>
            <!-- src="http://www.youtube.com/embed/dQw4w9WgXcQ" -->
        </v-card-text>
        <v-card-actions class="py-4 d-flex justify-end">
          <v-btn text color="primary" @click="dialog=false" >닫기</v-btn>
        </v-card-actions>
       </v-card> 
      </v-dialog>
    </v-container>
  </template>
  
  <script>
  // import VuePdfEmbed from 'vue-pdf-embed'
  
  // OR THE FOLLOWING IMPORT FOR VUE 2
  import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
  
  /* eslint-disable-next-line no-useless-escape */
  const youtubeRegex = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/
  // /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|vi|e(?:mbed)?)\/|\S*?[?&]v=|(?:watch\?(?:[^#&\n\s]+&)?v=))|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  // /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/
  export default {
    components: {
      VuePdfEmbed,
    },
    data() {
      return {
        currPage: 1,
        pageCount: 0,
        isLoading: true,
        // source1: require('@/assets/sample.pdf'),
        source1: require('@/assets/linktest.pdf'),
        // source1: 'https://www.antennahouse.com/hubfs/xsl-fo-sample/pdf/basic-link-1.pdf',
        // source2: 'data:application/pdf;base64,<BASE64_ENCODED_PDF>',
  
        // ytsrc:"",
        dialog:false,
      }
    },
    methods: {
      handleDocumentRender() {
        this.isLoading = false
        this.pageCount = this.$refs.pdfRef.pageCount
        // console.log(this.$refs.pdfRef)
        this.attachExternalLinkListener();
      },
      handleInternalLink(destPage){
        this.currPage = destPage
      },
      attachExternalLinkListener() {
        // PDF 뷰어 컨테이너에서 모든 <a> 태그를 가져옵니다.
        const anchorTags = this.$refs.pdfRef.$el.querySelectorAll(
          '.vue-pdf-embed__page a:not(.internalLink)'
        );
  
  
        // 각 <a> 태그를 반복합니다.
        anchorTags.forEach((anchor) => {
          // href 속성이 있는지 확인합니다.
          if (anchor.href) {
            // href가 외부 링크인지 확인합니다.
            // if (!anchor.href.startsWith('#')) {
  
              // 외부 링크에 대한 클릭 이벤트 리스너를 추가합니다.
              anchor.addEventListener('click', (event) => {
                // 기본 링크 동작을 방지합니다.
                event.preventDefault();
  
                
        console.log(anchorTags)
        console.log(this.$refs.player)
        console.log(document.getElementById('player'))
        
  
                // 외부 링크를 새 창에서 엽니다.
                // window.open(anchor.href, '_blank');
                let testlink = "https://youtu.be/KiiTjwrOKjk?si=cz3xqZpUHFcGMuZk"
                console.log(testlink.match(youtubeRegex))
  
                // if(youtubeRegex.test(testlink)){
                if(youtubeRegex.test(anchor.href)){
                  // const videoId = testlink.match(youtubeRegex)[5];
                  const videoId = anchor.href.match(youtubeRegex)[5];
                  const iframe = this.$refs.player;
                  iframe.src = `https://www.youtube.com/embed/${videoId}`;
                  // iframe.src = `https://www.youtube.com/embed/KiiTjwrOKjk`;
                  // this.ytsrc = "http://www.youtube.com/embed/dQw4w9WgXcQ"
              this.dialog=true;
                }else{
                  window.open(anchor.href, '_blank');
                }
              // }
              });
            // }
          }
        });
      },
      // handleResize(){
      //   this.$refs.pdfRef.render()
      // }
      // TODO: loading-failed, rendering-failed
    },
    
    // mounted() {
      
    //   window.addEventListener('resize', this.handleResize);
    // },
    
    // beforeDestroy() {
    //       // console.log("beforeDestroy...");
    //       window.removeEventListener('resize', this.handleResize);
    //   },
  }
  </script>
  
  <style >
  .annotationLayer .linkAnnotation{
    border-style:none !important;
  }
  
  </style>