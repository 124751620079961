<!-- 8 -->
<template>
    <div id="channel-home">
      <div class="nav-bgcolor">
        <div id="channel-header">
          <v-container class="py-0">
            <v-row class="justify-space-between">
              <v-col cols="12" sm="5" md="5" lg="5" offset-md="1">
                <v-card class="transparent" flat>
                    
                    <v-list-item three-line>
                      <v-list-item-avatar size="80" class="align-self-center" color="grey lighten-3"
                      ><v-img
                        :src="require('@/assets/grape_logo_only.png')"
                      ></v-img
                    ></v-list-item-avatar>
                    <v-list-item-content class="align-self-auto">
                      <v-list-item-title class="headline mb-1"
                        >나무</v-list-item-title
                      >
                      <v-list-item-subtitle
                        >
                        @test
                      </v-list-item-subtitle>
                      <v-list-item-subtitle class="py-3"
                        >
                        <v-btn rounded><v-icon>mdi-account-box-multiple-outline</v-icon>계정 전환</v-btn>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </div>
        <v-card flat class="transparent px-5">

            <v-card class="transparent" flat>
                <v-card-title>
                    <div class="d-flex justify-space-between align-center" style="width: 100%;" >

                    <span>기록
                    </span>

                    <v-btn rounded outlined>모두 보기</v-btn>

                    </div>
                </v-card-title>
                  <!-- <v-sheet class="mx-auto"> -->
                  <v-slide-group class="pa-4" multiple show-arrows>
                    <v-slide-item v-for="i in loading ? 5 : 10" :key="i">
                      <v-skeleton-loader
                        type="card-avatar"
                        :loading="loading"
                        width="250px"
                        class="mr-1"
                      >
                        <video-card
                          :card="{ maxWidth: 250, type: 'noAvatar' }"
                          :video="video"
                          :channel="channel"
                        ></video-card>
                        <!-- <v-card class="content-bg card" max-width="250" flat tile>
                          <v-img
                            src="https://cdn.vuetifyjs.com/images/cards/sunshine.jpg"
                            height="200px"
                          ></v-img>
                          <v-row no-gutters>
                            <v-col>
                              <v-card-title
                                class="pl-2 pt-3 subtitle-1 font-weight-bold"
                              >
                                Top western road trips
                              </v-card-title>
  
                              <v-card-subtitle class="pl-2 pb-0">
                                1,000 miles of wonder
                              </v-card-subtitle>
                              <v-card-subtitle class="pl-2 pt-0">
                                9.6k views<v-icon>mdi-circle-small</v-icon>6 hours
                                ago
                              </v-card-subtitle>
                            </v-col>
                          </v-row>
                        </v-card> -->
                      </v-skeleton-loader>
                    </v-slide-item>
                  </v-slide-group>
                </v-card>
                <v-card class="transparent" flat>
                  <v-card-title>
                    <div class="d-flex justify-space-between align-center" style="width: 100%;" >
                        <div class="d-flex align-center">
                            감상 목록 
                  <v-select
                    :items="['가나다순','최신순']"
                    label=""
                    style="width: 100px"
                    value='가나다순'
                    class="ml-10"
                  ></v-select>
                        </div>
                        <v-btn rounded outlined>모두 보기</v-btn>
                    </div>
                </v-card-title>
                  <!-- <v-sheet class="mx-auto"> -->
                  <v-slide-group class="pa-4" multiple show-arrows>
                    <v-slide-item v-for="i in loading ? 5 : 10" :key="i">
                      <v-skeleton-loader
                        type="card-avatar"
                        :loading="loading"
                        width="250px"
                        class="mr-1"
                      >
                        <video-card
                          :card="{ maxWidth: 250, type: 'noAvatar' }"
                          :video="video"
                          :channel="channel"
                        ></video-card>
                        <!-- <v-card class="content-bg card" max-width="250" flat tile>
                          <v-img
                            src="https://cdn.vuetifyjs.com/images/cards/sunshine.jpg"
                            height="200px"
                          ></v-img>
                          <v-row no-gutters>
                            <v-col>
                              <v-card-title
                                class="pl-2 pt-3 subtitle-1 font-weight-bold"
                              >
                                Top western road trips
                              </v-card-title>
  
                              <v-card-subtitle class="pl-2 pb-0">
                                1,000 miles of wonder
                              </v-card-subtitle>
                              <v-card-subtitle class="pl-2 pt-0">
                                9.6k views<v-icon>mdi-circle-small</v-icon>6 hours
                                ago
                              </v-card-subtitle>
                            </v-col>
                          </v-row>
                        </v-card> -->
                      </v-skeleton-loader>
                    </v-slide-item>
                  </v-slide-group>
                </v-card>

        </v-card>
      </div>
    </div>
  </template>
  
  <script>
  import VideoCard from '@/components/VideoCard'
  
  export default {
    data: () => ({
      disclosureItems:[
        {title:'공개'},
        {title:'비공개'},
      ],
      disclosure: '공개',
      communityUploadDialog:false,
      sortItems:[
        {title:'추가된 날짜(최신순)'},
        {title:'최근 추가된 콘텐츠순'},
      ],
      content_type: 'recent',
      tab: null,
      loading: true,
      items: ['홈', '콘텐츠', '감상 목록', '커뮤니티'],
      video: {
        url: '/watch/12',
        thumb: require('@/assets/thumbnails/thumbnail_07.jpg'),
        title: '콘텐츠 이름',
        views: '96만 회',
        createdAt: '6 시간 전'
      },
      channel: {
        url: '/channels/12',
        avatar: 'https://randomuser.me/api/portraits/men/1.jpg'
      },
      cdialog:false,
    }),
    components: {
      VideoCard,
    },
    methods:{
      uploadImage(){
        this.$refs.uploadImage.click();
      },
    },
    mounted() {
      setTimeout(() => {
        this.loading = false
      }, 1000)
    }
  }
  </script>
  
  <style>
  .nav-bgcolor {
    /* background: #f9f9f9; */
  }
  
  .card {
    /* background: #f9f9f9 !important; */
  }
  
  .v-tab {
    margin-right: 4em;
  }
  
  #channel-home .v-list-item--link:before {
    background-color: transparent;
  }
  
  .no-vbtnstyle {
       text-transform: unset !important;
       letter-spacing: unset !important;
  }
  </style>
  