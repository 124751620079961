<!-- 8-D-7 -->
<!-- TODO rollback textcolor -->
<template>
  <div class="pa-4 pl-0">
    <!-- <v-container fluid> -->
    <h2>채널 콘텐츠</h2>

    <!-- <v-row> -->
    <v-tabs v-model="tab" class="mt-5">
      <v-tab>
        레이아웃
      </v-tab>
      <v-tab>
        브랜딩
      </v-tab>
      <v-tab>
        기본 정보
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      
    
    <div class="d-flex justify-end mb-2">
      <v-btn class="mr-2" rounded >채널 보기</v-btn>
      <v-btn class="mr-2" rounded disabled>취소</v-btn>
      <v-btn class="mr-2" rounded disabled>게시</v-btn>

  </div>
      <v-tab-item>
        <template>
          <v-card flat>

            
            <v-card-title>
              <div class="d-flex flex-column">
                주목 받는 콘텐츠
                <p class="caption mb-0">
                  채널 홈페이지의 상단에 콘텐츠를 추가합니다.
                </p>
              </div>
            </v-card-title>
            <v-card outlined class="ma-3">
              <div class="d-flex justify-space-between align-center px-2">
                <v-btn
                  fab
                  small
                  color="secondary"
                  elevation="0"
                >
                  <v-icon>mdi-filmstrip</v-icon>
                </v-btn>
                <v-card-text>
                  <p class="mb-1">비구독자 대상 채널 트레일러</p>
                  <p class="caption mb-0">아직 구독하지 않은 사용자에게 표시되는 채널 미리보기를 공유합니다.
                    <v-icon small class="ml-1">mdi-help-circle-outline</v-icon>
                  </p>
                </v-card-text>
                <v-card-actions>
                  <v-btn rounded>추가</v-btn>
                </v-card-actions>
              </div>
            </v-card>
            <v-card outlined class="ma-3">
              <div class="d-flex justify-space-between align-center px-2 ">
                <v-btn
                  fab
                  small
                  color="secondary"
                  elevation="0"
                >
                  <v-icon>mdi-movie-filter-outline</v-icon>
                </v-btn>
                <v-card-text>
                  <p class="mb-1">재방문 구독자 대상 추천 콘텐츠</p>
                  <p class="caption mb-0">구독자에게 추천할 콘텐츠를 강조 표시합니다. 구독자가 이미 콘텐츠를 감상한 경우 콘텐츠가 페이지 상단에 다시 표시되지 않습니다.</p>
                </v-card-text>
                <v-card-actions>
                  <v-btn rounded>추가</v-btn>
                </v-card-actions>
              </div>
            </v-card>
          </v-card>
        </template>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-title>
              <div class="d-flex flex-column">
                사진
                <p class="caption mb-0">
                  프로필 사진은 콘텐츠 및 댓글 옆과 같이 Grape 에서 채널을 나타내는 위치에 표시됩니다.
                </p>
              </div>
            </v-card-title>
            <v-card-text>
              
            <div class="d-flex ">

              <v-avatar
                width="200"
                height="125"
                color="grey"
                class="flex-grow-0 flex-shrink-0"

                tile
              >
              <v-avatar
              size="100"
              color="grey lighten-3"
              >
              <v-img :src="require('@/assets/grape_logo_only.png')" alt="alt"/>
             </v-avatar>
             </v-avatar>

              <div class="px-5">
                <p>
                  98 * 98 픽셀 이상, 48M 이하의 사진이 권장됩니다. PNG 또는 GIF(애니메이션 GIF제외) 파일을 사용하세요. 사진이 Grape 커뮤니티 가이드를 준수해야 합니다.
                </p>
                <v-btn rounded class="mr-2">변경</v-btn>
                <v-btn rounded>삭제</v-btn>
              </div>
            </div>
          </v-card-text>
          </v-card>

          <v-card flat  >
            <v-card-title>
              <div class="d-flex flex-column">
                배너 이미지
                <p class="caption mb-0">
                  이 이미지가 채널 상단에 표시됩니다.
                </p>
              </div> 
            </v-card-title>
            <v-card-text>
              
            <div class="d-flex ">

              <v-avatar
                width="200"
                height="125"
                color="grey"
                class="flex-grow-0 flex-shrink-0"
                tile
              >
              <v-avatar
              size="100"
              tile
              color="grey lighten-3"
              >
              <img :src="require('@/assets/grape_logo_only.png')" alt="alt">
             </v-avatar>
             </v-avatar>

              <div class="px-5">
                <p>
                  모든 기기에 최적화된 이미지가 표시되도록 2048*1152 픽셀 이상, 6MB이하의 이미지를 사용하세요.
                  <v-icon small class="ml-1 mb-1">mdi-help-circle-outline</v-icon>
                </p>
                
<input type="file" id="upload-image" hidden ref="uploadImage" multiple />
                <v-btn rounded class="mr-2" 
              @click="uploadImage"
                
                >업로드</v-btn>
              </div>
            </div>
          </v-card-text>
          </v-card>

        </v-tab-item>
        <v-tab-item>

          <template>
  <v-form>
    <v-container>
      <v-row>
        <v-col
          cols="12"
          
        >
        <h3>이름</h3>
        <div class="text-subtitle-2 my-2">나와 내 콘텐츠를 잘 나타내는 채널 이름을 선택하세요. 변경된 이름 및 프로필 사진은 Grape에만 표시됩니다. 이름은 14일 내에 3회 변경할 수 있습니다. 
          </div>

          <v-text-field outlined
            label="이름"
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          
        >
        <h3>핸들</h3>
        <div class="text-subtitle-2 my-2">문자 및 숫자를 추가하여 고유한 핸들을 선택하세요. 14일 이내에 핸들을 다시 변경 할 수 있습니다. 핸들은 14일 마다 2번 변경 할 수 있습니다. <v-icon small class="ml-1 mb-1">mdi-help-circle-outline</v-icon>
          </div>

          <v-text-field outlined
            label="핸들"
            :value="handle"
            prefix="@"
            :hint="`https://grape.com/@${handle}`"
            persistent-hint
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          
        >
        <h3 class="mb-2">설명</h3>

          <v-textarea outlined
            placeholder="감상자에게 채널에 대해 설명해 주세요. 이 설명은 채널의 정보 섹션 및 검색결과 등에 표시됩니다. "
          ></v-textarea>
          <v-btn rounded><v-icon>mdi-plus</v-icon>언어 추가</v-btn>
        </v-col>

        <v-col
          cols="12"
          
        >
        <h3>채널 URL</h3>
        <div class="text-subtitle-2 my-2">채널의 표준 웹 주소입니다. 여기에는 URL끝에 있는 숫자와 문자 조합인 고유 채널 ID 가 포함되어 있습니다. <v-icon small class="ml-1 mb-1">mdi-help-circle-outline</v-icon>
          </div>

          <v-text-field outlined
            :value="`https://grape.com/channel/${uniqueId}`"
            append-icon="mdi-content-copy"
            readonly
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          
        >
        <h3>링크</h3>
        <div class="text-subtitle-2 my-2">감상자와 외부 링크를 공유합니다. 링크는 채널 프로필과 정보 페이지에 표시됩니다.
          </div>
          <v-btn rounded><v-icon>mdi-plus</v-icon>링크 추가</v-btn>

        </v-col>

        <v-col
          cols="12"
          
        >
        <h3>연락처 정보</h3>
        <div class="text-subtitle-2 my-2">비즈니스 문의를 할 수 있는 연락처를 제공하세요. 입력한 주소가 채널의 정보 섹션에 표시될 수 있으며 감상자가 볼 수 있습니다.
          </div>

          <v-text-field outlined
            label="이메일"
            placeholder="이메일 주소"
          ></v-text-field>
        </v-col>

      </v-row>
    </v-container>
  </v-form>
</template>
          
        </v-tab-item>


      </v-tabs-items>


    </div>

</template>

<script>
export default {
  data() {
    return {
      tab: null,
      handle: 'grape',
      uniqueId:'CNSVPOISDVKJ',
    }
    },
    methods: {
      uploadImage(){
      this.$refs.uploadImage.click();
    },
  },
}

</script>