<!-- 8-A -->
<template>
    <div id="channel-home">
      <v-parallax
        height="230"        
      :src="require('@/assets/channelbanner.jpg')"
      ></v-parallax>
      <v-divider></v-divider>
      <div class="nav-bgcolor">
        <div id="channel-header">
          <v-container class="py-0">
            <v-row class="justify-space-between">
              <v-col cols="12" sm="6" md="6" lg="6" offset-md="1">
                <v-card class="transparent" flat>
                  <v-list-item three-line>  
                    <v-list-item-avatar color="grey lighten-3" size="80">
    <v-img class="elevation-6" :src="require('@/assets/grape_logo_only.png')"></v-img>
  </v-list-item-avatar>
                    <v-list-item-content class="align-self-auto">
                      <v-list-item-title class="headline mb-1"
                        >나무</v-list-item-title
                      >
                      <v-list-item-subtitle
                        >
                        @test
                        •
                        구독자 46만명
                        •
                        콘텐츠 720 개
                      </v-list-item-subtitle>
                      <v-list-item-subtitle class="my-1"
                        >
                        <!-- Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolores libero velit aut adipisci et mollitia, saepe exercitationem est ipsa tempore, sint vero voluptatum tenetur dolorem! Debitis dolorum eius eveniet at. -->
                        
  <div>
    <v-btn text small  @click.stop="cdialog = true"
      >더보기</v-btn
    >
    <channel-details-dialog v-model="cdialog"></channel-details-dialog>
  </div>
                        <!-- TODO : more button -->
                      </v-list-item-subtitle>
                      <v-list-item-subtitle class="text--primary">
                        <a href="https://example.com"  class="v-btn v-btn--text primary--text no-vbtnstyle">Go to Example</a>
                        외 링크 1개
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>
              <v-col cols="12" sm="5" md="5" lg="5" class="text-right">
                 <v-btn rounded class="ma-2">채널 맞춤설정</v-btn>
                 <v-btn rounded class="ma-2">콘텐츠 관리</v-btn>
                <!-- <v-btn class="white--text mt-6" tile large depressed
                  color="#5c2e91"
                  >구독</v-btn
                >
                <v-btn class="ml-3 mt-6" tile large depressed
                  >가입</v-btn
                > -->
                <!-- <v-btn icon class="ml-5 mt-6"><v-icon>mdi-bell</v-icon></v-btn> -->
              </v-col>
            </v-row>
          </v-container>
        </div>
        <v-card flat class="transparent px-5">
          <v-tabs
            v-model="tab"
            background-color="transparent"
            show-arrows
            center-active
          >
            <v-tab v-for="(item, i) in items" :key="i">
              {{ item }}
            </v-tab>
          </v-tabs>
  
          <v-container fluid>
            <v-tabs-items v-model="tab" class="transparent">
              <v-tab-item>
                <v-card class="transparent" flat>
                    <div class = "text-center justify-center mx-auto" style="max-width:350px;">
                        <v-img
                        :src="require('@/assets/placeholders/placeholder1.png')"
                        max-height="300px"
                        max-width="300px"
                        class="mx-auto"
                        contain
                        ></v-img>
                        <!-- TODO : change img -->
                        <p class="mt-3 py-3">원하는 기기에서 콘텐츠를 만드세요</p>
                        <p>집에서는 물론 이동중에도 창작하고 업로드 할 수 있습니다. 공개상태로 설정한 모든 콘텐츠가 여기에 표시됩니다.</p>

                        <v-btn rounded>만들기</v-btn>
                    </div>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card class="transparent" flat>
                    <div class = "text-center justify-center mx-auto" style="max-width:350px;">
                        <v-img
                        :src="require('@/assets/placeholders/placeholder1.png')"
                        max-height="300px"
                        max-width="300px"
                        class="mx-auto"
                        ></v-img>
                        <!-- TODO : change img -->
                        <p class="mt-3">원하는 기기에서 콘텐츠를 만드세요</p>
                        <p>집에서는 물론 이동중에도 창작하고 업로드 할 수 있습니다. 공개상태로 설정한 모든 콘텐츠가 여기에 표시됩니다.</p>

                        <v-btn rounded>만들기</v-btn>
                    </div>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card class="transparent" flat>
                    <div class = "text-center justify-center mx-auto" style="max-width:350px;">
                        <v-img
                        :src="require('@/assets/placeholders/placeholder1.png')"

                        max-height="300px"
                        max-width="300px"
                        class="mx-auto"
                        ></v-img>
                        <!-- TODO : change img -->
                        <p class="mt-3">원하는 기기에서 콘텐츠를 만드세요</p>
                        <p>집에서는 물론 이동중에도 창작하고 업로드 할 수 있습니다. 공개상태로 설정한 모든 콘텐츠가 여기에 표시됩니다.</p>

                        <v-btn rounded>만들기</v-btn>
                    </div>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card class="transparent" flat>
                    <div class = "text-center justify-center mx-auto" style="max-width:350px;">
                        <v-img
                        :src="require('@/assets/placeholders/placeholder1.png')"

                        max-height="300px"
                        max-width="300px"
                        class="mx-auto"
                        ></v-img>
                        <!-- TODO : change img -->
                        <p class="mt-3">원하는 기기에서 콘텐츠를 만드세요</p>
                        <p>집에서는 물론 이동중에도 창작하고 업로드 할 수 있습니다. 공개상태로 설정한 모든 콘텐츠가 여기에 표시됩니다.</p>

                        <v-btn rounded>만들기</v-btn>
                    </div>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card class="transparent" flat>
                    <div class = "text-center justify-center mx-auto" style="max-width:350px;">
                        <v-img
                        src="https://picsum.photos/500/300"
                        max-height="300px"
                        max-width="300px"
                        class="mx-auto"
                        ></v-img>
                        <!-- TODO : change img -->
                        <p class="mt-3">원하는 기기에서 콘텐츠를 만드세요</p>
                        <p>집에서는 물론 이동중에도 창작하고 업로드 할 수 있습니다. 공개상태로 설정한 모든 콘텐츠가 여기에 표시됩니다.</p>

                        <v-btn rounded>만들기</v-btn>
                    </div>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card class="transparent" flat>
                    <div class = "text-center justify-center mx-auto" style="max-width:350px;">
                        <v-img
                        src="https://picsum.photos/500/300"
                        max-height="300px"
                        max-width="300px"
                        class="mx-auto"
                        ></v-img>
                        <!-- TODO : change img -->
                        <p class="mt-3">원하는 기기에서 콘텐츠를 만드세요</p>
                        <p>집에서는 물론 이동중에도 창작하고 업로드 할 수 있습니다. 공개상태로 설정한 모든 콘텐츠가 여기에 표시됩니다.</p>

                        <v-btn rounded>만들기</v-btn>
                    </div>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card class="transparent" flat>
                    <div class = "text-center justify-center mx-auto" style="max-width:350px;">
                        <v-img
                        src="https://picsum.photos/500/300"
                        max-height="300px"
                        max-width="300px"
                        class="mx-auto"
                        ></v-img>
                        <!-- TODO : change img -->
                        <p class="mt-3">원하는 기기에서 콘텐츠를 만드세요</p>
                        <p>집에서는 물론 이동중에도 창작하고 업로드 할 수 있습니다. 공개상태로 설정한 모든 콘텐츠가 여기에 표시됩니다.</p>

                        <v-btn rounded>만들기</v-btn>
                    </div>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card class="transparent" flat>
                    <div class = "text-center justify-center mx-auto" style="max-width:350px;">
                        <v-img
                        src="https://picsum.photos/500/300"
                        max-height="300px"
                        max-width="300px"
                        class="mx-auto"
                        ></v-img>
                        <!-- TODO : change img -->
                        <p class="mt-3">원하는 기기에서 콘텐츠를 만드세요</p>
                        <p>집에서는 물론 이동중에도 창작하고 업로드 할 수 있습니다. 공개상태로 설정한 모든 콘텐츠가 여기에 표시됩니다.</p>

                        <v-btn rounded>만들기</v-btn>
                    </div>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card class="transparent" flat>
                  <v-card-title>Uploads</v-card-title>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                      lg="3"
                      v-for="i in loading ? 10 : 12"
                      :key="i"
                      class="mx-xs-auto"
                    >
                      <v-skeleton-loader type="card-avatar" :loading="loading">
                        <video-card
                          :card="{ maxWidth: 350 }"
                          :video="video"
                          :channel="channel"
                        ></video-card>
                        <!-- <v-card
                          class="content-bg card mx-auto"
                          max-width="350"
                          flat
                          tile
                        >
                          <v-img
                            src="https://cdn.vuetifyjs.com/images/cards/sunshine.jpg"
                            height="200px"
                          ></v-img>
                          <v-row no-gutters>
                            <v-col cols="2">
                              <v-list-item class="pl-0 pt-3">
                                <v-list-item-avatar color="grey darken-3">
                                  <v-img
                                    class="elevation-6"
                                    src="https://randomuser.me/api/portraits/men/1.jpg"
                                  ></v-img>
                                </v-list-item-avatar>
                              </v-list-item>
                            </v-col>
                            <v-col>
                              <v-card-title
                                class="pl-2 pt-3 subtitle-1 font-weight-bold"
                              >
                                Top western road trips
                              </v-card-title>
  
                              <v-card-subtitle class="pl-2 pb-0">
                                1,000 miles of wonder
                              </v-card-subtitle>
                              <v-card-subtitle class="pl-2 pt-0">
                                9.6k views<v-icon>mdi-circle-small</v-icon>6 hours
                                ago
                              </v-card-subtitle>
                            </v-col>
                          </v-row>
                        </v-card> -->
                      </v-skeleton-loader>
                    </v-col>
                  </v-row>
                  <v-card-text>home</v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-container>
        </v-card>
      </div>
    </div>
  </template>
  
  <script>
  import VideoCard from '@/components/VideoCard'
import ChannelDetailsDialog from '@/components/dialogs/ChannelDetailsDialog.vue';

  export default {
    data: () => ({
      tab: null,
      loading: true,
      items: ['홈', '콘텐츠', '감상 목록', '커뮤니티'],
      video: {
        url: '/watch/12',
        thumb: 'https://cdn.vuetifyjs.com/images/cards/sunshine.jpg',
        title: 'Top western road trips',
        views: '96만 회',
        createdAt: '6 시간 전'
      },
      channel: {
        url: '/channels/12',
        avatar: 'https://randomuser.me/api/portraits/men/1.jpg'
      },
      cdialog:false,
    }),
    components: {
      VideoCard,
      ChannelDetailsDialog
    },
    mounted() {
      setTimeout(() => {
        this.loading = false
      }, 1000)
    }
  }
  </script>
  
  <style>
  .nav-bgcolor {
    /* background: #f9f9f9; */
  }
  
  .card {
    /* background: #f9f9f9 !important; */
  }
  
  .v-tab {
    margin-right: 4em;
  }
  
  #channel-home .v-list-item--link:before {
    background-color: transparent;
  }
  
  .no-vbtnstyle {
       text-transform: unset !important;
       letter-spacing: unset !important;
  }
  </style>
  