<!-- 8-D-1 -->
 <!-- TODO: ASK GOLIVE -->
<template>
  <div id="dashboard" class="pa-4">
    <v-container fluid>
      <div class="d-flex justify-space-between mb-5">
        <h2>채널 대시보드</h2>
        <div class="right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltip }">
              <!-- <v-btn
                @click="dialog = true"
                class="mr-4 white"
                icon
                v-on="{ ...tooltip }"
                ><v-icon size="25" class="small">mdi-upload</v-icon></v-btn
              > -->
              <v-btn
                @click="dialog = true"
                class="mr-4"
                icon
                outlined
                v-on="{ ...tooltip }"
                ><v-icon size="25" class="small">mdi-upload</v-icon></v-btn
              >
            </template>
            <span>콘텐츠 업로드</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltip }">
              <!-- <v-btn class="white" icon v-on="{ ...tooltip }" -->
              <v-btn icon outlined class="mr-4" v-on="{ ...tooltip }"
                ><v-icon size="25" class="small"
                  >mdi-access-point</v-icon
                ></v-btn
              >
            </template>
            <!-- TODO: tooltip? -->
            <span></span>
          </v-tooltip>
          
          <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltip }">
              <!-- <v-btn class="white" icon v-on="{ ...tooltip }" -->
              <v-btn icon outlined v-on="{ ...tooltip }"
                ><v-icon size="25" class="small"
                  >mdi-square-edit-outline</v-icon
                ></v-btn
              >
            </template>
            <!-- TODO: tooltip? -->
            <span>편집</span>
          </v-tooltip>
        </div>
      </div>
      <v-row>
        <v-col cols="12" sm="6" md="4">
          <v-card class="mx-auto py-15" outlined>
            <v-card-text>
                
                <div class = "text-center justify-center mx-auto" >
            <v-btn
              class="grey lighten-2 mb-4"
              style="height: 104px;width: 104px;"
                @click="dialog = true"
              ><v-icon x-large class="grey--text text--darken-1"
                >mdi-upload</v-icon
              ></v-btn
            >
                        <!-- TODO : change img -->
                        <p class="mt-3">최근 콘텐츠의 특정 항목을 보고 싶으신가요? 시작하시려면 콘텐츠를 업로드하고 게시하세요</p>

                        <v-btn 
                        @click="dialog = true">콘텐츠 업로드</v-btn>
                    </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-card class="mx-auto" outlined>
            <v-card-title class="pl-5">채널 분석</v-card-title>

            <v-card-subtitle class="caption pl-5 pb-0"
              >현재 구독자 수</v-card-subtitle
            >
            <h1 class="pl-5">1</h1>
            <p class="pl-5 caption grey--text">
              <span class="green--text">+1</span> 지난 28일
            </p>
            <v-divider></v-divider>
            <h5 class="pl-6 mt-4 mb-0">요약</h5>
            <v-card-subtitle class="pl-6 pb-0 pt-0 caption"
              >지난 28일</v-card-subtitle
            >
            <v-card-text>
              <v-list flat class="pl-0 pt-0">
                <v-list-item class="pl-2 mt-1" style="min-height:30px">
                  <v-list-item-title class="caption grey--text"
                    >조회수</v-list-item-title
                  >
                  <span class="caption grey--text">5</span>
                </v-list-item>

                <v-list-item class="pl-2" style="min-height: 30px">
                  <v-list-item-title class="caption grey--text"
                    >감상 시간(단위 시간)</v-list-item-title
                  >
                  <span class="caption grey--text">0.0</span>
                </v-list-item>
              </v-list>
            </v-card-text>
            <v-divider></v-divider>
            <h5 class="pl-5 mt-4 pb-0">인기 콘텐츠</h5>
            <v-card-subtitle class="pl-6 pt-0 pb-0 caption"
              >지난 48시간 · 조회수</v-card-subtitle
            >

            <v-card-actions class="d-block ml-2 my-2">
              <v-btn rounded color="secondary">
                채널 분석으로 이동
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="4" class="d-flex justify-space-between">
            <v-btn text color="secondary" class="px-1">이용약관</v-btn>
            <v-btn text color="secondary" class="px-1">개인정보처리지침</v-btn>
            <v-btn text color="secondary" class="px-1">정책 및 안전</v-btn>
        </v-col>
      </v-row>
    </v-container>
    <upload-video-modal
      :open-dialog="dialog"
      v-on:closeDialog="dialog = false"
    />
    <subscribers-modal
      :open-dialog="subscribersDialog"
      @closeDialog="subscribersDialog = false"
    />
  </div>
</template>

<script>
import UploadVideoModal from '@/components/UploadVideoModal'
import SubscribersModal from '@/components/SubscribersModal'
export default {
  data: () => ({
    loading: true,
    dialog: false,
    subscribersDialog: false
  }),
  components: {
    UploadVideoModal,
    SubscribersModal
  },
  mounted() {}
}
</script>

<style lang="scss">
.card {
  // background: #f9f9f9 !important;
}
</style>
