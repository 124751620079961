<!-- 8-D-10 -->
<!-- add query '?type=file' to access 8-D-10+(save original button) -->
<template>
    <v-container fluid>
      <v-row class="mb-1">
        <v-spacer></v-spacer>
        <v-col cols="3"></v-col>
        <v-col cols="3" relative>

        
        <h3>용기 </h3>
        <h4 class="secondary--text">[ {{ currPage +1 }} / 총{{ totalPage }} ]</h4>
  
        </v-col>
        <v-col cols="6" class="error--text text-subtitle-2">
            <v-icon
              color="error"
              dark
            >
              mdi-copyright
            </v-icon>
          <span >무단으로 작품을 복사하거나 배포하는 저작권 침해 행위는 법적 처분을 받습니다.</span>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
        <template>
          <!-- <v-skeleton-loader
          :loading=loading
          type="image"
          transition="scale-transition"
          > -->
            <tui-image-editor ref="tuiImageEditor" :include-ui="useDefaultUI" :options="options" style="margin: 0 auto; height: 800px;" ></tui-image-editor>

          <!-- </v-skeleton-loader> -->

        </template>

        <template>

          <v-tooltip
          v-model="firstPageToolTip"
          absolute
          bottom
          right
          content-class="custom-tooltip"
          attach=".tui-image-editor-controls"
          >
          <span>첫 페이지 입니다.</span>
        </v-tooltip>
      </template>
        <template>

          <v-tooltip
          v-model="lastPageToolTip"
          absolute
          bottom
          right
          content-class="custom-tooltip"
          attach=".tui-image-editor-controls"
          >
          <span>마지막 페이지 입니다.</span>
        </v-tooltip>
      </template>
      
      <template>
        <div class="d-flex justify-end my-2">
          <v-btn class="ma-1" @click.stop="tdialog = true">언어</v-btn>
          <translate-dialog v-model="tdialog"></translate-dialog>
          <template v-if = "this.$route.query.type !=='file'">

          <v-btn class="ma-1" >미리보기</v-btn> <!-- route to view -->
          <v-tooltip bottom :open-on-hover="false">
            <template #activator="{ on }">
                <v-btn class="ma-1" @click="on.click" @blur="on.blur" retain-focus-on-click>임시저장</v-btn>
                
            </template>
            <span>임시저장 완료</span>
          </v-tooltip>
        </template>

        <template v-else>
          <v-btn class="ma-1" >원본 저장</v-btn> 
        </template>

          <v-btn class="ma-1" color="primary"
          router
          :to="`/studio/translation/submit${this.$route.query.type === undefined ? '' : '?type='+this.$route.query.type}`"
          >다음</v-btn>
        </div>
      </template>

    </v-container>
</template>

<script>
import 'tui-color-picker/dist/tui-color-picker.css';
import 'tui-image-editor/dist/tui-image-editor.css';
import { ImageEditor } from '@toast-ui/vue-image-editor';
import TranslateDialog from '@/components/dialogs/TranslateDialog.vue';


export default {
  components: {
    'tui-image-editor': ImageEditor,
    TranslateDialog,
  },
  data() {
    return {
      loading:true,
      tdialog:false,
      currPage: 2,
      totalPage: 7,
      firstPageToolTip : false,
      lastPageToolTip : false,
      useDefaultUI: true,
      options: {
    includeUI: {
        loadImage: {
          path: require('@/assets/brave/brave_3.jpg'),
          name: 'SampleImage'
        },
        // theme: this.$vuetify.theme.themes.dark, // or whiteTheme
        menu: ['draw', 'shape','text'],
        initMenu: 'text',
        uiSize: {
            width: '100%',
            height: '1000px'
        },
        menuBarPosition: 'top'
        },
        cssMaxWidth: 700,
        cssMaxHeight: 500,
        // selectionStyle: {
        //   cornerSize: 20,
        //   rotatingPointOffset: 70
        // },
        usageStatistics: false,
  
      },
    };
  },
  mounted() {
    console.log(this.$route.query.id)
    const requireImage = require.context('@/assets/brave/', false, /\.jpg$/);
    // let currPage = 2;
    let editorInstance = this.$refs.tuiImageEditor.editorInstance;
    let imgData = ['brave_1.jpg','brave_2.jpg','brave_3.jpg','brave_4.jpg','brave_5.jpg','brave_6.jpg','brave_7.jpg',];

    // console.log(Vue.compile("<v-btn>tt</v-btn>"))

    // document.querySelector(".tui-image-editor-menu-text .tui-image-editor-submenu-item").insertAdjacentElement("afterbegin",testel)
    // editorInstance.loadImageFromURL('https://picsum.photos/200/300', 'SampleImage').then(result => {
    //   console.log('old : ' + result.oldWidth + ', ' + result.oldHeight);
    //   console.log('new : ' + result.newWidth + ', ' + result.newHeight);
    // })

  //Font select list
      //--------------------------------------
      //Any installed web font from Google will work: https://fonts.google.com/
      let fontArray = ["Noto Sans","Arial", "Arial Black", "Caveat", "Comic Sans MS", "Courier New","Georgia1","Impact","Lobster Two", "Lucida Console","Luckiest Guy", "Open Sans","Pacifico", "Palatino Linotype","Press Start 2P", "Roboto", "Tahoma", "Tangerine", "Times New Roman","Tourney","Ultra", "Verdana","Symbol","Webdings","Wingdings","Noto Sans KR","Noto Serif KR","Jua"];

      let fontSelectHTML = '<select #fontselect default="Noto Sans" class="form-select font-selector" style="color: #e9e9e9;background: #1f1f1f; padding:0 5px; border-radius:5px">';
      for (let i = 0; i < fontArray.length; i++) {
        let selected = '';
        if(i == 0){
          selected = 'selected';
        }
        fontSelectHTML += '<option style="font-family:'+fontArray[i]+';" value="'+fontArray[i]+'" '+selected+'>'+fontArray[i]+'</option>';
      }
      fontSelectHTML +=  '</select>';

      let textMenuAlign = document.querySelector('.tui-image-editor-menu-text .tie-text-align-button');
      textMenuAlign.insertAdjacentHTML('afterbegin', fontSelectHTML);

      document.querySelector('.font-selector').addEventListener('change', () =>
        this.TUI_updateFontOnText(document.querySelector('.font-selector').value));
      //-------------------------------------

    //ON TUI objectActivated
    //   Keep track of active/selected item
    editorInstance.on('objectActivated', props => {
      this.TUI_selectedItem = props;
      this.TUI_updateFontSelected(props);
      console.log('TUI_selectedItem', props);
    });

     /********************************
   * Update font familty on text layer
   *******************************/
  this.TUI_updateFontOnText=(font) =>{
    console.log("TUI_updateFontOnText", font, this.TUI_selectedItem.id);

    if(font){
      this.TUI_selectedFont = font;
    }

    if(font && this.TUI_selectedItem){
      editorInstance.changeTextStyle(this.TUI_selectedItem.id, {
        fontFamily: font
      });
    }
  }


    /********************************
     * Update font selected with the fontfamily of the selected layer
     *******************************/
    this.TUI_updateFontSelected = (layer)=> {
      console.log("TUI_updateFontSelected", layer);

      if(layer.fontFamily){
        document.querySelector('.font-selector').value = layer.fontFamily;
        this.TUI_selectedFont = layer.fontFamily;
      }
    }

    document.querySelector(".tui-image-editor-container .tui-image-editor-header-logo").style="display:none !important"
    document.querySelectorAll(".tui-image-editor-controls-buttons>*").forEach((el)=>{el.style.display="none"})
    document.querySelector(".tui-image-editor-controls-buttons").insertAdjacentHTML("afterbegin",`
    <button class="tui-image-editor-prev-btn" style="background-color: #5c2e91;border: 1px solid #5c2e91;color: #fff;font-family: 'Roboto', sans-serif;font-size: 12px; margin-right:5px">
                이전 페이지
    </button><button class="tui-image-editor-next-btn" style="background-color: #5c2e91;border: 1px solid #5c2e91;color: #fff;font-family: 'Roboto', sans-serif;font-size: 12px">
        다음 페이지
    </button>
            `)

    document.querySelector(".tui-image-editor-prev-btn").addEventListener("click",()=>{
      if (this.currPage==0) {
        this.firstPageToolTip = true;
        setTimeout(() => {
          this.firstPageToolTip = false;
        }, 1000);
      }
      this.currPage = Math.max (0,this.currPage-1)
      console.log("prev : " + this.currPage)
      console.log(editorInstance.loadImageFromURL)
      console.log(imgData[0])
      console.log(this.firstPageToolTip)
      // let s= '@/assets/brave/brave_2.jpg'
      // editorInstance.loadImageFromURL(require('@/assets/brave/brave_2.jpg'),"d")
      // editorInstance.loadImageFromURL(require(s),"d")
      
      // const imageName = 'brave_2.jpg';

      editorInstance.loadImageFromURL(requireImage(`./${imgData[this.currPage]}`), "d");

       
    })
    document.querySelector(".tui-image-editor-next-btn").addEventListener("click",()=>{
      if (this.currPage==imgData.length-1) {
        this.lastPageToolTip = true;
        setTimeout(() => {
          this.lastPageToolTip = false;
        }, 1000);
      }
      this.currPage = Math.min(imgData.length-1,this.currPage+1)
      
      editorInstance.loadImageFromURL(requireImage(`./${imgData[this.currPage]}`), "d");
    })

    // setTimeout(() => {
    //   this.loading=false;
    //   console.log(111)
    // }, 1000);
  }
};

</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Jua&family=Noto+Sans+KR:wght@100..900&family=Noto+Serif+KR:wght@200..900&display=swap');

/* .tui-image-editor-container .tui-image-editor-header-logo {
  display: none !important;
} */
/* .v-tooltip--attached {
  display: inline;
}
.v-tooltip__content {
  background: rgba(97, 97, 97, 0.9);
  color: #FFFFFF;
  border-radius: 4px;
  font-size: 14px;
  line-height: 22px;
  display: inline-block;
  padding: 5px 16px;
  position: absolute;
  text-transform: initial;
  width: auto;
  opacity: 0;
  pointer-events: none;
}
.v-tooltip__content.menuable__content__active {
  opacity: 0.9;
}
.v-tooltip__content--fixed {
  position: fixed;
}
.v-tooltip__content[class*=-active] {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}
.v-tooltip__content[class*=enter-active] {
  transition-duration: 150ms;
}
.v-tooltip__content[class*=leave-active] {
  transition-duration: 75ms;
} */



</style>

<style scoped>

div.custom-tooltip {
  background: rgba(97, 97, 97, 0.9);
  color: #FFFFFF;
  border-radius: 4px;
  font-size: 14px;
  line-height: 22px;
  display: inline-block;
  padding: 5px 16px;
  /* position: absolute; */
  text-transform: initial;
  width: auto;
  pointer-events: none;
  z-index: 999;
}
</style>