<!-- 7-B -->
<template>
        
        <!-- Fab Group Column -->

        <v-container fluid class="position-relative">
            
          <v-row>
          <!-- <v-row style="position:fixed; width:100%; background-color: black; color:white; z-index:999;"> -->
            <v-spacer></v-spacer>
            <v-col cols="6">
              <div>
                <h3>용기</h3>
                <p class="text-subtitle-1 secondary--text mb-0">[{{currPage}}/총{{totalPages}}]</p>
              </div>
            </v-col>  
            <v-spacer></v-spacer>
          </v-row>
              
        <div v-if="scrollMode">
        <v-row justify="center">
        <v-col cols="8" class="d-flex justify-center">
            <v-virtual-scroll
                :items="images"
                :item-height="700"
            >
                <template v-slot:default="{ item }">
                <v-list-item>
                    <v-img :src="item" height="700" width="500" contain></v-img>
                </v-list-item>
                </template>
            </v-virtual-scroll>
        
          </v-col>
          
      <v-scroll-x-transition>

          <v-col cols="4" v-if="commentOn">
                    <p class="mb-0">148 댓글</p>
                    <input type="text" ref="hello" />
                    <v-card class="transparent" flat>
                      <v-list-item three-line class="pl-0">
                        <v-list-item-avatar size="50" color="grey lighten-3"
                          ><v-img
                            :src="require('@/assets/grape_logo_only.png')"
                          ></v-img
                        ></v-list-item-avatar>
                        <v-list-item-content class="align-self-auto">
                          <v-text-field
                            v-model="comment"
                            placeholder="댓글 쓰기..."
                            @click="showCommentBtns = true"
                          >
                          </v-text-field>
                          <div
                            v-if="showCommentBtns"
                            class="d-inline-block text-right"
                          >
                            <v-btn
                              text
                              @click="showCommentBtns = !showCommentBtns"
                              >취소</v-btn
                            >
                            <v-btn
                              class="blue darken-3 white--text"
                              depressed
                              tile
                              :disabled="comment === ''"
                              >확인</v-btn
                            >
                          </div>
                        </v-list-item-content>
                      </v-list-item>
                    </v-card>

                    <v-card class="transparent" flat v-for="i in 5" :key="i">
                      <v-list-item three-line class="pl-0 mt-2">
                        <v-list-item-avatar size="50" color="grey lighten-3"
                          ><v-img
                            :src="require('@/assets/grape_logo_only.png')"
                          ></v-img
                        ></v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title
                            class="font-weight-medium caption mb-1"
                            >나무
                            <span class="font-weight-light grey--text">
                              1 일 전</span
                            ></v-list-item-title
                          >
                          <v-list-item-subtitle
                            class="text--darken-4 caption"
                            >Lorem ipsum, dolor sit amet consectetur adipisicing
                            elit. Tempore deleniti aspernatur nostrum eius
                            dignissimos repellendus. Fugiat, aspernatur deserunt
                            iusto natus consectetur voluptatem voluptate
                            laboriosam pariatur qui animi repudiandae quae
                            dolorem.</v-list-item-subtitle
                          >
                          <!-- <v-list-item-action> -->
                          <div>
                            <!-- <button
                              class="caption font-weight-bold d-inline-block pa-2 grey--text text--darken-3"
                              style="cursor: pointer; outline: none"
                              @click.stop.prevent="showReply"
                            >
                              REPLY
                            </button> -->
                            <v-btn
                              text
                              small
                              :ripple="false"
                              @click.stop="showReply(`${'reply' + i}`)"
                              >답글 달기</v-btn
                            >
                          </div>
                          <div class="d-none" :ref="`${'reply' + i}`">
                            <v-list-item three-line class="pl-0">
                              <v-list-item-avatar class="mt-0" size="40" color="grey lighten-3"
                                ><v-img
                                  :src="require('@/assets/grape_logo_only.png')"
                                ></v-img
                              ></v-list-item-avatar>
                              <v-list-item-content
                                class="align-self-auto mt-0 pt-0"
                              >
                                <v-form :ref="`form${i}`">
                                  <v-text-field
                                    :ref="`${'input' + i}`"
                                    class="pt-0 mt-0 body-2"
                                    placeholder="답글 쓰기..."
                                    :value="repliesInput[`input${i}`]"
                                  >
                                  </v-text-field>
                                </v-form>
                                <div
                                  :ref="i + 'btns'"
                                  class="d-inline-block text-right"
                                >
                                  <v-btn text @click="hideReply(i)" small
                                    >취소</v-btn
                                  >
                                  <v-btn
                                    class="blue darken-3 white--text"
                                    depressed
                                    tile
                                    small
                                    @click="addReply(i)"
                                    >확인</v-btn
                                  >
                                </div>
                              </v-list-item-content>
                            </v-list-item>
                            <!-- </v-list-item-action> -->
                          </div>
                        </v-list-item-content>
                      </v-list-item>
                    </v-card>
                  </v-col>
      </v-scroll-x-transition>

         </v-row>
        </div>
        <div v-else>
            
        <v-row justify="center">
        <v-col cols="8" class="d-flex justify-center">

            <template>
              <div
              
              @mouseenter="showControls"
              @mouseleave="hideControls"
              style="width:100%"
              >
            <v-carousel v-model="model"
            
            :show-arrows="isHovering"
            :hide-delimiter-background="true"
            :hide-delimiters="true"
            
            >
                <v-carousel-item
                v-for="(page, i) in images"
                :key="i"
                >
                <v-sheet
                    height="100%"
                    width="100%"
                    tile
                >
                    <!-- <v-row
                    class="fill-height"
                    align="center"
                    justify="center"
                    > -->
                          <v-img
                          :src="page" :alt="'brave '+i"
                          
                          height="100%"
                          
                          width="100%"
                          contain
                          ></v-img>
                    <!-- </v-row> -->
                </v-sheet>
                </v-carousel-item>
            </v-carousel>

            </div>
            </template>
            
          </v-col>
          <!-- TODO:TRANSITION -->
          <v-col cols="4" v-if="commentOn">
                    <p class="mb-0">148 답글</p>
                    <input type="text" ref="hello" />
                    <v-card class="transparent" flat>
                      <v-list-item three-line class="pl-0">
                        <v-list-item-avatar size="50" color="grey lighten-3"
                          ><v-img
                          :src="require('@/assets/grape_logo_only.png')"
                          ></v-img
                        ></v-list-item-avatar>
                        <v-list-item-content class="align-self-auto">
                          <v-text-field
                            v-model="comment"
                            placeholder="댓글 쓰기..."
                            @click="showCommentBtns = true"
                          >
                          </v-text-field>
                          <div
                            v-if="showCommentBtns"
                            class="d-inline-block text-right"
                          >
                            <v-btn
                              text
                              @click="showCommentBtns = !showCommentBtns"
                              >취소</v-btn
                            >
                            <v-btn
                              class="blue darken-3 white--text"
                              depressed
                              tile
                              :disabled="comment === ''"
                              >확인</v-btn
                            >
                          </div>
                        </v-list-item-content>
                      </v-list-item>
                    </v-card>

                    <v-card class="transparent" flat v-for="i in 5" :key="i">
                      <v-list-item three-line class="pl-0 mt-2">
                        <v-list-item-avatar size="50" color="grey lighten-3"
                          ><v-img
                            :src="require('@/assets/grape_logo_only.png')"
                          ></v-img
                        ></v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title
                            class="font-weight-medium caption mb-1"
                            >나무
                            <span class="font-weight-light grey--text">
                              1 일전</span
                            ></v-list-item-title
                          >
                          <v-list-item-subtitle
                            class="text--darken-4 caption"
                            >Lorem ipsum, dolor sit amet consectetur adipisicing
                            elit. Tempore deleniti aspernatur nostrum eius
                            dignissimos repellendus. Fugiat, aspernatur deserunt
                            iusto natus consectetur voluptatem voluptate
                            laboriosam pariatur qui animi repudiandae quae
                            dolorem.</v-list-item-subtitle
                          >
                          <!-- <v-list-item-action> -->
                          <div>
                            <!-- <button
                              class="caption font-weight-bold d-inline-block pa-2 grey--text text--darken-3"
                              style="cursor: pointer; outline: none"
                              @click.stop.prevent="showReply"
                            >
                              REPLY
                            </button> -->
                            <v-btn
                              text
                              small
                              :ripple="false"
                              @click.stop="showReply(`${'reply' + i}`)"
                              >답글 쓰기</v-btn
                            >
                          </div>
                          <div class="d-none" :ref="`${'reply' + i}`">
                            <v-list-item three-line class="pl-0">
                              <v-list-item-avatar class="mt-0" size="40" color="grey lighten-3"
                                ><v-img
                                  :src="require('@/assets/grape_logo_only.png')"
                                ></v-img
                              ></v-list-item-avatar>
                              <v-list-item-content
                                class="align-self-auto mt-0 pt-0"
                              >
                                <v-form :ref="`form${i}`">
                                  <v-text-field
                                    :ref="`${'input' + i}`"
                                    class="pt-0 mt-0 body-2"
                                    placeholder="답글 쓰기..."
                                    :value="repliesInput[`input${i}`]"
                                  >
                                  </v-text-field>
                                </v-form>
                                <div
                                  :ref="i + 'btns'"
                                  class="d-inline-block text-right"
                                >
                                  <v-btn text @click="hideReply(i)" small
                                    >취소</v-btn
                                  >
                                  <v-btn
                                    class="blue darken-3 white--text"
                                    depressed
                                    tile
                                    small
                                    @click="addReply(i)"
                                    >확인</v-btn
                                  >
                                </div>
                              </v-list-item-content>
                            </v-list-item>
                            <!-- </v-list-item-action> -->
                          </div>
                        </v-list-item-content>
                      </v-list-item>
                    </v-card>
                  </v-col>
         </v-row>
        </div>
        <v-footer
        fixed
        >
          <v-container fluid>
            <v-row>
              <v-spacer></v-spacer>
               <v-col cols="6" class="text-center d-flex justify-space-between">
                <v-btn ><v-icon>mdi-chevron-left</v-icon>이전화 </v-btn>
                <v-btn text ><v-icon>mdi-arrow-u-left-top</v-icon></v-btn>
                <!-- TODO: 회차 DIALOG -->
                <v-dialog
                  v-model="episodeDialog"
                  max-width="500px"
                >

                <template v-slot:activator="{ on, attrs }">
                  <v-btn text 
                    v-bind="attrs"
                    v-on="on"
                  ><v-icon class="mr-1">mdi-format-list-bulleted</v-icon> 회차</v-btn>
                </template>    
                       
                 <v-card>
                  <v-card-title >
                    <div class="d-flex justify-space-between align-center" style="width: 100%;" >
                      <span>
                        용기
                      </span>
                      <v-btn icon><v-icon>mdi-close</v-icon></v-btn>
                    </div>
                  </v-card-title>
                  <v-card-text>
                    
                    <v-list>
                      <v-list-item-group
                        v-model="currEpisode"
                        color="primary"
                      >
                      
                      <v-list-item v-for="(item, index) in episodes" :key="index" dense @click="$router.push(`/reader/comics?episode=${item.episode}`); episodeDialog=false">  
                        <v-list-item-content>
                          <v-list-item-title>{{formattedEpisode(item.episode)}} - {{item.title}}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                    </v-list>
                      
                  </v-card-text>
                 </v-card>
              
                 </v-dialog>
                <v-btn >다음화 <v-icon>mdi-chevron-right</v-icon></v-btn>
                </v-col>
              <v-spacer></v-spacer>

            </v-row>
          </v-container>
          
          <template>
          <div class="position-sticky btngroups">
          <v-speed-dial
                v-model="fab"
                direction="top"
                transition="slide-y-reverse-transition"
              >
                <template v-slot:activator>
                  <v-btn v-model="fab" color="primary" fab>
                    <v-icon v-if="fab">mdi-close</v-icon>
                    <v-icon v-else>mdi-chevron-up</v-icon>
                  </v-btn>
                </template>
                
                <v-btn fab dark small>
                <v-avatar
                  size="40"
                  @click="$router.push('/channels/10')"
                >
                  <img src='https://randomuser.me/api/portraits/men/1.jpg' alt="alt">
                </v-avatar>
                </v-btn>

                <!-- TODO : MENUS -->
                 
        <template>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                v-bind="attrs"
                v-on="on"
                @click.stop.prevent
                small
              >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
            </template>
              <v-list>
              <v-list-item router to="#">
                <v-list-item-icon>
                  <v-icon>mdi-text</v-icon>
                </v-list-item-icon>
                <v-list-item-title>설명</v-list-item-title>
              </v-list-item>
              <v-list-item router to="#">
                <v-list-item-icon>
                  <v-icon>mdi-plus-box-multiple-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>감상 목록에 저장</v-list-item-title>
              </v-list-item>
              <v-list-item router to="#">
                <v-list-item-icon>
                  <v-icon>mdi-translate</v-icon>
                </v-list-item-icon>
                <v-list-item-title>번역</v-list-item-title>
              </v-list-item>
              
              <v-divider></v-divider>
            
              <v-list-item router to="#">
                <v-list-item-icon>
                  <v-icon>mdi-minus-circle-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>채널 추천 안함</v-list-item-title>
              </v-list-item>
              <v-list-item router to="#">
                <v-list-item-icon>
                  <v-icon>mdi-flag-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>신고</v-list-item-title>
              </v-list-item>
              <v-list-item router to="#">
                <v-list-item-icon>
                  <v-icon>mdi-message-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>의견 보내기</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
                
                
                <v-btn fab dark small @click="sdialog=true">
                  <v-icon>mdi-share</v-icon>
                </v-btn>

                <v-btn fab dark small @click="commentToggle">
                  <v-icon>mdi-comment-text</v-icon>
                </v-btn>
                
                
                <v-btn fab dark small @click="scrollModeToggle">
                  <v-icon v-if="scrollMode">mdi-book-open-outline</v-icon>
                  <v-icon v-else>mdi-mouse-move-vertical</v-icon>
                </v-btn>
                <v-btn fab dark small>
                  <v-icon>mdi-refresh-auto</v-icon>
                </v-btn>
                <v-btn fab dark small>
                  <v-icon>mdi-thumb-down-outline</v-icon>
                </v-btn>
                <v-btn fab dark small>
                  <v-icon>mdi-thumb-up-outline</v-icon>
                </v-btn>
              </v-speed-dial>
          </div>
        </template>
        </v-footer>
        
        <social-dialog v-model="sdialog"></social-dialog>
        </v-container>
  </template>
  
  <script>
import SocialDialog from '@/components/dialogs/SocialDialog.vue';
export default {
    data() {
      return {
        episodeDialog:false,
        currEpisode:0,
        episodes:[
          {
            episode:1,
            title:'용기 1화',
          },
          {
            episode:2,
            title:'용기 2화',
          },
          {
            episode:3,
            title:'용기 3화',
          },
          {
            episode:4,
            title:'용기 4화',
          },
        ],
        
    isHovering:false,
        commentOn:false,
      sdialog: false,
      images: [
        require('@/assets/brave/brave_1.jpg'),
        require('@/assets/brave/brave_2.jpg'),
        require('@/assets/brave/brave_3.jpg'),
        require('@/assets/brave/brave_4.jpg'),
        require('@/assets/brave/brave_5.jpg'),
          // 더 많은 이미지 URL을 추가할 수 있습니다.
        ],
        fab: true,
        scrollMode: 'true',
        
        model: 0,
        currPage: 1,
        totalPages: 5,
        
        
    truncate: true,
    comment: '',
    showCommentBtns: false,
    repliesInput: {},
      };
    },
    methods: {
      scrollModeToggle() {
        this.scrollMode =!this.scrollMode;
      },
      commentToggle(){
        this.commentOn =!this.commentOn;
        console.log(this.commentOn);
      },


      showControls() {
      this.isHovering = true
    },
    hideControls() {
      this.isHovering = false
    },
    
    formattedEpisode(episode){
      return String(episode).padStart(4,'0')
    },

    showReply(id) {
      this.$refs[id][0].classList.toggle('d-none')
    },
    hideReply(id) {
      this.$refs[`form${id}`][0].reset()
      this.$refs['reply' + id][0].classList.toggle('d-none')
    },
    addReply(id) {
      this.$refs[`form${id}`][0].reset()
      console.log(this.$refs[`input${id}`][0].$refs.input.value)
    },
    show(event) {
      if (event.target.innerText === 'SHOW MORE') {
        this.truncate = false
        event.target.innerText = 'SHOW LESS'
      } else {
        this.truncate = true
        event.target.innerText = 'SHOW MORE'
      }
    },
    truncateText(string = '', num) {
      if (string.length <= num) {
        return string
      }
      return string.slice(0, num)
    },
      
    },
    
  components: {
    SocialDialog,
  },

  computed:{
  }
  };
  </script>
  
  <style>
  .v-virtual-scroll {
    overflow-y: auto;
  }
  .btngroups{
    position: fixed;
    bottom: 10px;
    right: 10px;
  }
  </style>
  