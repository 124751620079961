<!-- 8-D-2 -->
 <!-- TODO:v-tab number -->
<template>
  <v-container fluid>
    <h2>채널 분석</h2>
    
    <v-tabs>
    <div class="d-flex" style="width: 100%;">
    <v-tab>개요</v-tab>
    <v-tab>콘텐츠</v-tab>
    <v-tab>감상자층</v-tab>
    <v-spacer></v-spacer>
    <v-select
          :items="items"
          :label="timeIntervalText"
          :value="items[0]"
          style="max-width: 200px;"
        ></v-select>
    </div>


  <!-- <v-tab-items v-model="tab"> -->
    <v-tab-item>
      <v-container fluid>
        <v-row>
          <v-col cols="8">
            <h2 class="text-subtitle-1 text-center my-5">지난 28일 동안 채널의 조회수가 없습니다</h2>
            
            
      <template >
        <v-card class="innerChart" outlined>
        <v-tabs
          v-model="tab"
          grow
        >
          <v-tab
          >
          조회수
          </v-tab>
          <v-tab
          >
          감상 시간 (단위 : 시간)
          </v-tab>
          <v-tab
          >
          구독자
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item
            v-for="item in [1,2,3]"
            :key="item"
          >
            <v-card
              flat
            >
              <v-card-text>
                <v-sparkline
              color="primary"
              :line-width="width"
              :padding="padding"
              :smooth="false"
              :value="value"
              :labels="labels"
              auto-draw
              label-size="5"
            ></v-sparkline>
            <v-btn rounded class="mt-2">더보기</v-btn>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
        </v-card>
      </template>
          </v-col>
          <v-col cols="4">
            <v-card>
              <v-card-title primary-title class="pb-0">
                실시간
              </v-card-title>
              <v-card-text class="pt-0">
                <v-badge
                  inline
                  dot
                ></v-badge>
                실시간 업데이트 중
              </v-card-text>
              <v-divider></v-divider>
              <v-card-text>
                <h3>0</h3>
                구독자 <br>
                <v-btn rounded class="mt-2">실시간 집계보기</v-btn>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-text>
                <h3>0</h3>
                조회수 : 지난 48시간
              
            <v-sparkline
              class="pa-0"
              color="gray"
              :line-width="width"
              :padding="10"
              :smooth="false"
              :value="[0,0]"
              :labels="['ㅤㅤㅤㅤ48시간 전','현재ㅤㅤ']"
              label-size="10"
            ></v-sparkline>
              <v-btn rounded class="mt-2">더보기</v-btn>
            </v-card-text>

            </v-card>
          </v-col>
        </v-row>
        
      </v-container>
    </v-tab-item>
    <v-tab-item>
      <template >
        <v-card class="innerChart mt-5" outlined>
        <v-tabs
          v-model="tab"
          grow
        >
          <v-tab
          >
          조회수
          </v-tab>
          <v-tab
          >
          노출수
          </v-tab>
          <v-tab
          >
          노출 클릭률
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item
            v-for="item in [1,2,3]"
            :key="item"
          >
            <v-card
              flat
            >
              <v-card-text>
                <v-sparkline
              color="primary"
              :line-width="width"
              :padding="padding"
              :smooth="false"
              :value="value"
              :labels="labels"
              auto-draw
              label-size="5"
            ></v-sparkline>
            <v-btn rounded class="mt-2">더보기</v-btn>
              </v-card-text>
            </v-card>
            <v-divider></v-divider>

          </v-tab-item>
        </v-tabs-items>
        </v-card>
        
        <v-card width="500px" class="mx-auto mt-3">
              <v-card-title primary-title class="mb-0">
                인기 감상기록
                <v-card-text class="mt-0">
                  조회수 지난 28일
                </v-card-text>
              </v-card-title>
              <v-card-text>
                이 기간에 대해서는 표시할 항목이 없습니다.
              </v-card-text>
              <v-card-text>
                <v-btn rounded class="mt-2">더보기</v-btn>
              </v-card-text>
            </v-card>
      </template>
    </v-tab-item>
    <v-tab-item>
      <template >
        <v-card class="innerChart mt-5" outlined ref="inC1">
        <v-tabs
          v-model="tab"
          grow
        >
          <v-tab
          >
          재방문 감상자
          </v-tab>
          <v-tab
          >
          순 감상자 층
          </v-tab>
          <v-tab
          >
          구독자
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item
            v-for="item in [1,2,3]"
            :key="item"
          >
            <v-card
              flat
            >
              <v-card-text>
                <v-sparkline
              color="primary"
              :line-width="width"
              :padding="padding"
              :smooth="false"
              :value="value"
              :labels="labels"
              auto-draw
              label-size="5"
            ></v-sparkline>
            <v-btn rounded class="mt-2">더보기</v-btn>
              </v-card-text>
            </v-card>
            <v-divider></v-divider>

          </v-tab-item>
        </v-tabs-items>
        </v-card>
        
        <v-card width="500px" class="mx-auto mt-3">
          <v-card-title primary-title class="mb-0">
            많이 본 지역
            <v-card-text class="mt-0">
              조회수 지난 28일
            </v-card-text>
          </v-card-title>
          <v-card-text>
            지역 데이터가 충분하지 않아 이 보고서를 조회 할 수 없습니다.
          </v-card-text>
          <v-card-text>
            <v-btn rounded class="mt-2">더보기</v-btn>
          </v-card-text>
        </v-card>
        <v-card width="500px" class="mx-auto mt-3">
          <v-card-title primary-title class="mb-0">
            인기 번역 언어
            <v-card-text class="mt-0">
              조회수 지난 28일
            </v-card-text>
          </v-card-title>
          <v-card-text>
            이 기간에 대해서는 표시할 항목이 없습니다.
          </v-card-text>
          <v-card-text>
            <v-btn rounded class="mt-2">더보기</v-btn>
          </v-card-text>
        </v-card>
        <v-card width="500px" class="mx-auto mt-3">
          <v-card-title primary-title class="mb-0">
            연령 및 성별
            <v-card-text class="mt-0">
              조회수 지난 28일
            </v-card-text>
          </v-card-title>
          <v-card-text>
            인구통계 데이터가충분하지 않아 이 보고서를 조회할 수 없습니다.
          </v-card-text>
          <v-card-text>
            <v-btn rounded class="mt-2">더보기</v-btn>
          </v-card-text>
        </v-card>
      </template>
    </v-tab-item>
  <!-- </v-tab-items> -->
  
</v-tabs>


  </v-container>
</template>

<script>
  const gradients = [
    ['skyblue'],
    ['#42b3f4'],
    ['red', 'orange', 'yellow'],
    ['purple', 'violet'],
    ['#00c6ff', '#F0F', '#FF0'],
    ['#f72047', '#ffd200', '#1feaea'],
  ]

  export default {
    data: () => ({
      fill: false,
      selectedGradient: gradients[0],
      gradients,
      padding: 8,
      radius: 0,
      // labels: [0, 2, 5, 9, 5, 10, 3, 5, 0, 0, 1, 8, 2, 9, 0],
      value: [0, 2, 5, 9, 5, 10, 3, 5, 0, 0, 1, 8, 2, 9, 0],
      width: 1,
      tab:null,
      items:["지난 28일","지난 3개월","지난 12개월"],
      timeIntervalText:"2024. 6. 3. ~ 2024. 6.30."
    }),
    mounted(){
      console.log(document.querySelectorAll('.innerChart'))
    },
  }
</script>

<style>
</style>