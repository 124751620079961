<!-- 8-D-11 8-D-12-->
<!-- add query '?type=file' to access 8-D-12(upload file) -->
<template>
    <v-container>
        <v-col class="d-flex justify-center">
            <v-card
            max-width="650"
            class="pa-10"
            rounded ="xxl"
            >
                
            <div class="d-flex error--text text-subtitle-2 align-center">
                
                <v-icon
                color="error"
                dark
                class="mr-2"
                >
                mdi-copyright
            </v-icon>
            <span >무단으로 작품을 복사하거나 배포하는 저작권 침해 행위는 법적 처분을 받습니다.</span>
            </div>
            
            <h2 class="my-5">
                번역 파트너십 정보 입력

            </h2>
            <v-flex>
                
                <v-form >
                    <!-- 채널 아이디는 왜 필요한건가요? -->
                    <v-text-field
                    v-model = "channelId"
                    placeholder="본인 채널 아이디를 입력해 주세요"
                    label="번역가 채널 아이디"
                    hint = "* 번역된 콘텐츠에 채널 이름이 표시됩니다."
                    persistent-hint
                    persistent-placeholder
                    >
                    </v-text-field>
                    <div>
                        <p class ="mt-3 mb-0">수익 배분</p>
                    <p class="text-caption secondary--text my-0"> 본 작품 원작자와의 번역된 콘텐츠 조회수에 대한 수익배분
                    </p>
                    <p class="text-caption error--text my-0"> * 작품 승인이 수락되면 원작자와 6개월 동안 파트너십 해지가 불가합니다.</p>        
                    </div>
                    <v-radio-group
                    v-model="rating"
                    row
                    class = "mt-0"
                    >
                    <v-radio
                        label="10%"
                        value="10"
                    ></v-radio>
                    <v-radio
                        label="20%"
                        value="20"
                    ></v-radio>
                    <v-radio
                        label="30%"
                        value="30"
                    ></v-radio>
                    <v-radio
                        label="40%"
                        value="40"
                    ></v-radio>
                    <v-radio
                        label="50%"
                        value="50"
                    ></v-radio>
                    <v-radio
                        :value="ratingEtc"
                    >
                    <template v-slot:label>
                        기타 
                    <div style="width: 70px;">
                        <v-text-field
                        class="ml-2"
                        maxlength="10"
                        :value="ratingEtc"
                        ></v-text-field>
                    </div>
                    
                </template>
                    </v-radio>
                    
                    </v-radio-group>

                    
                    <div>
                    <h3>번역가 포트폴리오</h3>
                    <p class="text-caption secondary--text my-0"> * 허위 경력을 기재할 경우 채널 등록 영구 제재를 받을 수 있습니다.</p>
                    </div>

                    <p class ="mt-2 mb-0">링크</p>
                    <v-text-field
                    dense
                    v-model="portfolioLink"
                    hint = "url을 입력해 주세요"
                    persistent-hint
                    class="ml-5"
                    >
                    </v-text-field>
                    
                    
                    <v-container fluid>
                        <v-row>

                        <v-col
                        sm="12"
                        md="6"
                        class="pa-0"
                        >
                        <p class ="my-2">포트폴리오 파일</p>

                        <v-btn
                        tile
                        elevation=0
                        class="grey lighten-2 mb-4 ml-5"
                        style="height: 130px;width: 200px;"
                        @click="selectFile1"
                        >
                        <div
                        transparent
                        >
                        <v-card-text
                            class="d-flex flex-column justify-center align-center"
                        >
                        <v-icon class="grey--text text--darken-1">mdi-image-plus-outline</v-icon>
                        <div class="grey--text text--darken-1">파일 선택</div>
                        </v-card-text>
                            
                        </div>
                        </v-btn>
                        </v-col>
                        
                        <v-col
                        v-if="this.$route.query.type==='file'"
                        sm="12"
                        md="6"
                        class="pa-0"
                        >
                        <p class ="my-2">번역된 파일</p>

                        <v-btn
                        tile
                        elevation=0
                        class="grey lighten-2 mb-4 ml-5"
                        style="height: 130px;width: 200px;"
                        @click="selectFile2"
                        >
                        <div
                        transparent
                        >
                        <v-card-text
                            class="d-flex flex-column justify-center align-center"
                        >
                        <v-icon class="grey--text text--darken-1">mdi-image-plus-outline</v-icon>
                        <div class="grey--text text--darken-1">파일 선택</div>
                        </v-card-text>
                            
                        </div>
                        </v-btn>
                        </v-col>
                         </v-row>

                    </v-container>

                    <div class="d-flex justify-end">
                       
                        <v-card outlined  width="400px">
                            <v-card-text class="pa-1">
                                
                                <div class="d-flex">

                                    <v-btn
                                    color="primary"
                                    @click="submit"
                                    >
                                    승인 요청
                                    </v-btn>
                                    <span class="text-caption secondary--text ml-2">
                                        본 작품의 원작자가 번역 계약에 대한 요청을 승인하면 번역된 콘텐츠가 자동으로 채널에 노출됩니다.
                                    </span>
                                </div>
                            </v-card-text>

                        </v-card>
                    </div>
                </v-form>
            </v-flex>

        </v-card>
        </v-col>
        <v-dialog
            v-model="submittedDialog"
            max-width="300px"
        >

        <v-card class="text-center">
              <v-card-title class="pt-10" >
                <div style="width: 100%;">

                  승인 요청 완료
                </div>
              </v-card-title>
              <v-card-text class="pb-10">
                감사합니다.
              </v-card-text>
              <v-btn color="primary" block @click="submittedDialog=false" class="py-5">확인</v-btn>
            </v-card>            
        </v-dialog>
    </v-container>
</template>

<script>
export default {
    data(){
        return{
            comment: '',
            channelId:'',
            rating:null,
            ratingEtc:null,
            portfolioLink:null,
            submittedDialog:false,
        }
    },
    methods:{
        submit(){
            this.submittedDialog=true
            
        },
        selectFile1(){},
        selectFile2(){},
    }
}
</script>

<style>
.v-messages__message{
    font-size: 10px;
}

</style>