import { render, staticRenderFns } from "./Copyright.vue?vue&type=template&id=aca265be&scoped=true"
var script = {}
import style0 from "./Copyright.vue?vue&type=style&index=0&id=aca265be&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aca265be",
  null
  
)

export default component.exports