<!-- 8 -->
<template>
  <div id="channel-home">
    <v-parallax
      height="230"
      :src="require('@/assets/channelbanner.jpg')"
    ></v-parallax>
    <v-divider></v-divider>
    <div class="nav-bgcolor">
      <div id="channel-header">
        <v-container class="py-0">
          <v-row class="justify-space-between">
            <v-col cols="12" sm="6" md="6" lg="6" offset-md="1">
              <v-card class="transparent" flat>
                <v-list-item three-line>
                  <v-list-item-avatar color="grey lighten-3" size="80">
                    <v-img class="elevation-6" :src="require('@/assets/grape_logo_only.png')" ></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content class="align-self-auto">
                    <v-list-item-title class="headline mb-1"
                      >나무</v-list-item-title
                    >
                    <v-list-item-subtitle
                      >
                      @test
                      •
                      구독자 46만명
                      •
                      콘텐츠 720 개
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="my-1"
                      >
                      <!-- Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolores libero velit aut adipisci et mollitia, saepe exercitationem est ipsa tempore, sint vero voluptatum tenetur dolorem! Debitis dolorum eius eveniet at. -->
  <div>
    <v-btn text small  @click.stop="cdialog = true"
      >더보기</v-btn
    >
    <channel-details-dialog v-model="cdialog"></channel-details-dialog>
  </div>
                      <!-- TODO : more button -->
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="text--primary">
                      <a href="https://example.com"  class="v-btn v-btn--text primary--text no-vbtnstyle">Go to Example</a>
                      외 링크 1개
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
            <v-col cols="12" sm="5" md="3" lg="3">
              <!-- red -> 5c2e91 -->
              <v-btn class="white--text mt-6" tile large depressed
                color="#5c2e91"
                >구독</v-btn
              >
              <v-btn class="ml-3 mt-6" tile large depressed
                >가입</v-btn
              >
              <!-- <v-btn icon class="ml-5 mt-6"><v-icon>mdi-bell</v-icon></v-btn> -->
            </v-col>
          </v-row>
        </v-container>
      </div>
      <v-card flat class="transparent px-5">
        <v-tabs
          v-model="tab"
          background-color="transparent"
          show-arrows
          center-active
        >
          <v-tab v-for="(item, i) in items" :key="i">
            {{ item }}
          </v-tab>
        </v-tabs>

        <v-container fluid>
          <v-tabs-items v-model="tab" class="transparent">
            <v-tab-item>
              <v-card class="transparent" flat>
                <v-card-title>홈</v-card-title>
                <!-- <v-sheet class="mx-auto"> -->
                <v-slide-group class="pa-4" multiple show-arrows>
                  <v-slide-item v-for="i in loading ? 5 : 10" :key="i">
                    <v-skeleton-loader
                      type="card-avatar"
                      :loading="loading"
                      width="250px"
                      class="mr-1"
                    >
                      <video-card
                        :card="{ maxWidth: 250, type: 'noAvatar' }"
                        :video="video"
                        :channel="channel"
                      ></video-card>
                      <!-- <v-card class="content-bg card" max-width="250" flat tile>
                        <v-img
                          src="https://cdn.vuetifyjs.com/images/cards/sunshine.jpg"
                          height="200px"
                        ></v-img>
                        <v-row no-gutters>
                          <v-col>
                            <v-card-title
                              class="pl-2 pt-3 subtitle-1 font-weight-bold"
                            >
                              Top western road trips
                            </v-card-title>

                            <v-card-subtitle class="pl-2 pb-0">
                              1,000 miles of wonder
                            </v-card-subtitle>
                            <v-card-subtitle class="pl-2 pt-0">
                              9.6k views<v-icon>mdi-circle-small</v-icon>6 hours
                              ago
                            </v-card-subtitle>
                          </v-col>
                        </v-row>
                      </v-card> -->
                    </v-skeleton-loader>
                  </v-slide-item>
                </v-slide-group>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card class="transparent" flat>
                <v-card-title>콘텐츠</v-card-title>
                
    <v-btn-toggle v-model="content_type " group>
      <v-btn
        class="ma-2"
        small
        label
        value="recent"
      >
        최신순
      </v-btn>
      <v-btn
        class="ma-2"
        small
        label
        value="hot"
      >
        인기순
      </v-btn>
      <v-btn
        class="ma-2"
        small
        label
        value="date"
      >
        날짜순
      </v-btn>
      <v-btn
        class="ma-2"
        small
        label
        value="ani"
      >
        애니
      </v-btn>

  </v-btn-toggle>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                    lg="3"
                    v-for="i in loading ? 10 : 12"
                    :key="i"
                    class="mx-xs-auto"
                  >
                    <v-skeleton-loader type="card-avatar" :loading="loading">
                      <video-card
                        :card="{ maxWidth: 350 }"
                        :video="video"
                        :channel="channel"
                      ></video-card>
                      <!-- <v-card
                        class="content-bg card mx-auto"
                        max-width="350"
                        flat
                        tile
                      >
                        <v-img
                          src="https://cdn.vuetifyjs.com/images/cards/sunshine.jpg"
                          height="200px"
                        ></v-img>
                        <v-row no-gutters>
                          <v-col cols="2">
                            <v-list-item class="pl-0 pt-3">
                              <v-list-item-avatar color="grey darken-3">
                                <v-img
                                  class="elevation-6"
                                  src="https://randomuser.me/api/portraits/men/1.jpg"
                                ></v-img>
                              </v-list-item-avatar>
                            </v-list-item>
                          </v-col>
                          <v-col>
                            <v-card-title
                              class="pl-2 pt-3 subtitle-1 font-weight-bold"
                            >
                              Top western road trips
                            </v-card-title>

                            <v-card-subtitle class="pl-2 pb-0">
                              1,000 miles of wonder
                            </v-card-subtitle>
                            <v-card-subtitle class="pl-2 pt-0">
                              9.6k views<v-icon>mdi-circle-small</v-icon>6 hours
                              ago
                            </v-card-subtitle>
                          </v-col>
                        </v-row>
                      </v-card> -->
                    </v-skeleton-loader>
                  </v-col>
                </v-row>
                <v-card-text>home</v-card-text>
              </v-card>
            </v-tab-item>
              
            <v-tab-item>
              <v-card class="transparent" flat>
                <v-card-title>
                  <div class="d-flex justify-space-between" style="width:100%">
                    <span>생성된 감상목록</span>
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
        text
        class="pl-1 mb-3"
        v-bind="attrs"
          v-on="on"
        >
                    <v-icon class="ma-2">mdi-sort-variant</v-icon>
                    정렬
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in sortItems"
          :key="index"
        >
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
                  </div>
                  </v-card-title>
                <!-- <v-sheet class="mx-auto"> -->
                <v-slide-group class="pa-4" multiple show-arrows>
                  <v-slide-item v-for="i in loading ? 5 : 10" :key="i">
                    <v-skeleton-loader
                      type="card-avatar"
                      :loading="loading"
                      width="250px"
                      class="mr-1"
                    >
                      <video-card
                        :card="{ maxWidth: 250, type: 'noAvatar' }"
                        :video="video"
                        :channel="channel"
                      ></video-card>
                      <!-- <v-card class="content-bg card" max-width="250" flat tile>
                        <v-img
                          src="https://cdn.vuetifyjs.com/images/cards/sunshine.jpg"
                          height="200px"
                        ></v-img>
                        <v-row no-gutters>
                          <v-col>
                            <v-card-title
                              class="pl-2 pt-3 subtitle-1 font-weight-bold"
                            >
                              Top western road trips
                            </v-card-title>

                            <v-card-subtitle class="pl-2 pb-0">
                              1,000 miles of wonder
                            </v-card-subtitle>
                            <v-card-subtitle class="pl-2 pt-0">
                              9.6k views<v-icon>mdi-circle-small</v-icon>6 hours
                              ago
                            </v-card-subtitle>
                          </v-col>
                        </v-row>
                      </v-card> -->
                    </v-skeleton-loader>
                  </v-slide-item>
                </v-slide-group>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <!-- TODO: DIALOG -->
              <v-card class="transparent text-center" flat>
                <v-card-text class="mt-5" >
                  
                  <v-btn x-large fab color="secondary" @click="communityUploadDialog=true">
                    <v-icon size="40">mdi-square-edit-outline</v-icon>
                  </v-btn>
                  <h3 class="ma-3" @click="communityUploadDialog=true">게시물 올리기</h3>
                  <p>게시 후에 게시물이 여기에 표시되고 커뮤니티에 공개됩니다.</p>
                </v-card-text>
                <v-dialog
                  v-model="communityUploadDialog"
                  max-width="500px"
                  transition="dialog-transition"
                >
                <v-card>
                  <v-card-title>
                    <div class="d-flex align-center" style="width:100%">
                      <v-avatar
                        size="25"
                        color="grey lighten-3"
                        class="mr-1"
                      >
                        <v-img :src="require('@/assets/grape_logo_only.png')" alt="alt"/>
                      </v-avatar>
                      <div class="ml-1 text-subtitle-1">나무</div>
                      
                      <div class="ml-auto">
                        <template>
  <div class="text-center">
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
        text
          v-bind="attrs"
          v-on="on"
        >
         공개상태 {{ disclosure }} 
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in disclosureItems"
          :key="index"
        >
          <v-list-item-title @click="disclosure=item.title">{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
                      </div>
                    </div>
                  </v-card-title>
                  <v-card-text>
                    <v-textarea
                    auto-grow
                    rows="1"
                    placeholder="메시지 작성..."
                    >

                    </v-textarea>
                    <v-card
                    outlined
                    class="text-center">
                      
                    <input type="file" id="upload-image" hidden ref="uploadImage" multiple />
                    <v-card-text  >
                      <div>
                          <v-btn x-large fab color="blue lighten-2" @click="uploadImage" >
                          <v-icon size="40">mdi-image-edit-outline</v-icon>
                          </v-btn>
                        </div>
                        <h3 class="ma-3">최대 5개의 이미지 또는 GIF를 드래그 하거나 <br> <a href="#" class="blue--text text--lighten-2 text-decoration-none" @click="uploadImage">컴퓨터에서 선택</a></h3>
                        <p>가로 세로 비율이 2:5에서 5:2사이인 이미지를 업로드 하세요. <br> 사용 권한이 있는 이미지 또는 GIF만 선택하세요.</p>
                      </v-card-text>
                    </v-card>
                  </v-card-text>
                  <v-card-actions>
                    <div class="d-flex justify-end" style="width: 100%;">
                      <v-btn text @click="communityUploadDialog=false">취소</v-btn>
                      <v-btn text color="primary" @click="communityUploadDialog=false">게시</v-btn>
                    </div>
                  </v-card-actions>
                </v-card>
                  
                </v-dialog>
                </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-container>
      </v-card>
    </div>
  </div>
</template>

<script>
import VideoCard from '@/components/VideoCard'
import ChannelDetailsDialog from '@/components/dialogs/ChannelDetailsDialog.vue';

export default {
  data: () => ({
    disclosureItems:[
      {title:'공개'},
      {title:'비공개'},
    ],
    disclosure: '공개',
    communityUploadDialog:false,
    sortItems:[
      {title:'추가된 날짜(최신순)'},
      {title:'최근 추가된 콘텐츠순'},
    ],
    content_type: 'recent',
    tab: null,
    loading: true,
    items: ['홈', '콘텐츠', '감상 목록', '커뮤니티'],
    video: {
      url: '/watch/12',
      thumb: require('@/assets/thumbnails/thumbnail_07.jpg'),
      title: '콘텐츠 이름',
      views: '96만 회',
      createdAt: '6 시간 전'
    },
    channel: {
      url: '/channels/12',
      avatar: 'https://randomuser.me/api/portraits/men/1.jpg'
    },
    cdialog:false,
  }),
  components: {
    VideoCard,
    ChannelDetailsDialog
  },
  methods:{
    uploadImage(){
      this.$refs.uploadImage.click();
    },
  },
  mounted() {
    setTimeout(() => {
      this.loading = false
    }, 1000)
  }
}
</script>

<style>
.nav-bgcolor {
  /* background: #f9f9f9; */
}

.card {
  /* background: #f9f9f9 !important; */
}

.v-tab {
  margin-right: 4em;
}

#channel-home .v-list-item--link:before {
  background-color: transparent;
}

.no-vbtnstyle {
     text-transform: unset !important;
     letter-spacing: unset !important;
}
</style>
