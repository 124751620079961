<!-- 8-C -->
<template>
    <v-container fluid>
          <v-card class="transparent" flat>
            <v-card-title>
                <div class="d-flex align-center" style="width:100%">
                    <span>
                        번역 요청함</span>
                    <v-select
                        :items="['가나다순','최신순']"
                        label=""
                        style="width: 100px"
                        value='가나다순'
                        class="ml-5 flex-grow-0"
                    ></v-select>
                    <v-btn class="ml-auto" rounded>모두 보기</v-btn>

                </div>
            </v-card-title>
            <!-- <v-sheet class="mx-auto"> -->
            <v-slide-group class="pa-4" multiple show-arrows>
              <v-slide-item v-for="i in loading ? 5 : 10" :key="i">
                <v-skeleton-loader
                  type="card-avatar"
                  :loading="loading"
                  width="250px"
                  class="mr-1"
                >
                  <video-card
                    :card="{ maxWidth: 250, type: 'noAvatar' }"
                    :video="video"
                    :channel="channel"
                  ></video-card>
                  <!-- <v-card class="content-bg card" max-width="250" flat tile>
                    <v-img
                      src="https://cdn.vuetifyjs.com/images/cards/sunshine.jpg"
                      height="200px"
                    ></v-img>
                    <v-row no-gutters>
                      <v-col>
                        <v-card-title
                          class="pl-2 pt-3 subtitle-1 font-weight-bold"
                        >
                          Top western road trips
                        </v-card-title>

                        <v-card-subtitle class="pl-2 pb-0">
                          1,000 miles of wonder
                        </v-card-subtitle>
                        <v-card-subtitle class="pl-2 pt-0">
                          9.6k views<v-icon>mdi-circle-small</v-icon>6 hours
                          ago
                        </v-card-subtitle>
                      </v-col>
                    </v-row>
                  </v-card> -->
                </v-skeleton-loader>
              </v-slide-item>
            </v-slide-group>
          </v-card>
          <v-card class="transparent" flat>
            <v-card-title >
                <div class="d-flex align-center" style="width:100%">
                    <span>
                        번역 승인함</span>
                    <v-select
                        :items="['가나다순','최신순']"
                        label=""
                        style="width: 100px"
                        value='가나다순'
                        class="ml-5 flex-grow-0"
                    ></v-select>
                    <v-btn class="ml-auto" rounded>모두 보기</v-btn>

                </div>
            </v-card-title>
            <!-- <v-sheet class="mx-auto"> -->
            <v-slide-group class="pa-4" multiple show-arrows>
              <v-slide-item v-for="i in loading ? 5 : 10" :key="i">
                <v-skeleton-loader
                  type="card-avatar"
                  :loading="loading"
                  width="250px"
                  class="mr-1"
                >
                  <video-card
                    :card="{ maxWidth: 250, type: 'noAvatar' }"
                    :video="video"
                    :channel="channel"
                  ></video-card>
                  <!-- <v-card class="content-bg card" max-width="250" flat tile>
                    <v-img
                      src="https://cdn.vuetifyjs.com/images/cards/sunshine.jpg"
                      height="200px"
                    ></v-img>
                    <v-row no-gutters>
                      <v-col>
                        <v-card-title
                          class="pl-2 pt-3 subtitle-1 font-weight-bold"
                        >
                          Top western road trips
                        </v-card-title>

                        <v-card-subtitle class="pl-2 pb-0">
                          1,000 miles of wonder
                        </v-card-subtitle>
                        <v-card-subtitle class="pl-2 pt-0">
                          9.6k views<v-icon>mdi-circle-small</v-icon>6 hours
                          ago
                        </v-card-subtitle>
                      </v-col>
                    </v-row>
                  </v-card> -->
                </v-skeleton-loader>
              </v-slide-item>
            </v-slide-group>
          </v-card>
    </v-container>
</template>


<script>
import VideoCard from '@/components/VideoCard'
export default {
data: () => ({    tab: null,
loading: true,
video: {
  url: '/studio/translation/confirm',
  thumb: require('@/assets/thumbnails/thumbnail_04.jpg'),
  title: 'xxx-번역',
  views: '채널이름',
  createdAt: ''
},
channel: {
  url: '/channels/12',
  avatar: "require('@/assets/grape_logo_only.png')"
}
}),
components: {
VideoCard
},
mounted() {
setTimeout(() => {
  this.loading = false
}, 1000)
}
}
</script>

<style>
.nav-bgcolor {
/* background: #f9f9f9; */
}

.card {
/* background: #f9f9f9 !important; */
}

.v-tab {
margin-right: 4em;
}

#channel-home .v-list-item--link:before {
background-color: transparent;
}

.no-vbtnstyle {
 text-transform: unset !important;
 letter-spacing: unset !important;
}
</style>
