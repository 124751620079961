<!-- 8-D-6 -->
<!-- TODO other things? -->
<template>
  <v-container>
    <h2 class="mb-4">Grape 에서의 수익 창출</h2>
    <template v-if="qualified==false">

    <v-row>
        <v-col>

            <p class="text-caption my-0"> 다음 목표 
                
                <v-badge
                icon="mdi-lock"
                color="secondary"
                inline
        ></v-badge>
        </p>
        <h3>수익 창출 시작하기</h3>
        
        <p class="text-caption my-0"> 팬과 소통하며 수익을 창출하고 지원 포럼과 크리에이터 도구의 이용 권한을 얻으세요.
        </p>
        </v-col>
    </v-row>
    <v-row>
        <v-col>
            <v-card
            outlined
            >
            
            <v-card-title>
                <div class="d-flex justify-space-between align-center" style="width:100%">
                    <span>멤버십 후원</span>
                    <v-btn
                        fab
                        small
                        text
                       @click="show = !show"
                    >
                    <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                </v-btn>
                </div>
            </v-card-title>
            
            <v-expand-transition>
            <div v-show="show">
            <div class="d-flex justify-space-between">
                <div>
                <v-card-text>
                    <p>커뮤니티를 키우고 매달 수익을 창출하세요.</p>
                    <p>감상자들이 좋아하고 재미있는 콘텐츠를 올리고 감상자가 내 채널과 콘텐츠에서 가입할 수 있게 만들어주세요. 광고 콘텐츠외 후원 수익은 크리에이터에게는 70%가 지급됩니다.</p>
                </v-card-text>
                </div>
                    <div class="d-flex align-center pr-4">
                        <v-avatar
                            size="100"
                            tile
                        >
                        <v-img
                        height="100"
                        width="100"
                        src="https://cdn.vuetifyjs.com/images/cards/cooking.png"
                        ></v-img>
                        </v-avatar>
                    </div>
                </div>
                <div>
                    <v-responsive min-height="100px">
                        <v-card-text>
                            <v-divider></v-divider>
                        </v-card-text>
                    </v-responsive>
                </div>
            </div>
            </v-expand-transition>

        </v-card>
        <p class="text-caption my-3"> 일부 혜택에는 추가 자격 기준이 적용 될 수 있습니다.
        </p>
        </v-col>
    </v-row>
    <v-row>
        <v-col>
            <h3>자격요건</h3>
        
            <p class="text-caption my-0"> 팬과 소통하며 수익을 창출하고 지원 포럼과 크리에이터 도구의 이용 권한을 얻으세요.
            </p>
            
            <v-subheader class="text-caption">{{ dateString }} 기준의 데이터 입니다.</v-subheader>
           
            <template>
            <v-progress-linear value="0"></v-progress-linear>
            <div class="d-flex justify-space-between mt-1 text-caption">
                <span>구독자 0명</span>
                <span>10,000</span>
            </div>
            </template>
            <template>
            <v-progress-linear class="mt-3" value="33"></v-progress-linear>
            <div class="d-flex justify-space-between mt-1 text-caption">
                <span style="line-height: 1.2;">
                    콘텐츠 업로드 1개 <br> 
                    지난 90일
                </span>
                <span>3</span>
            </div>
            </template>
            <div class="text-center text-caption" style="width: 100%;">
                그리고 다음 중 하나를 충족해야 함
            </div>
            <template>
            <v-progress-linear class="mt-3" value="0"></v-progress-linear>
            <div class="d-flex justify-space-between mt-1 text-caption">
                <span style="line-height: 1.2;">
                    공개 콘텐츠 감상 조회 : 0 조회 <br> 
                    최근 365일
                </span>
                <span>1,000,000</span>
            </div>
            </template>
        </v-col>
    </v-row>
    <v-row>
        <v-col>
            
            <div class="text-center" style="width: 100%;">
                자격요건을 충족하면 알려드릴까요?
                
            </div>
            <div class="text-center my-5" style="width: 100%;">
                <v-btn rounded>알림 받기</v-btn>
            </div>
        </v-col>
    </v-row>
    <v-row>
        <v-col>
            <v-card outlined>
                <v-card-title >
                    지원 후
                </v-card-title>
                <v-card-text >
                    <span class="undeco">
                        채널이 Grape <a>수익 창출 정책</a>을 준수하는지 확인합니다. 문제가 없는 것으로 확인되면 가입이 승인됩니다. 

                    </span>

                </v-card-text>
                <v-card-text>
                    <v-btn rounded @click = "qualified=true">자세히 알아보기</v-btn>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
    </template>
    <template v-else>
        
    <v-row>
        <v-col>

            <p class="text-caption my-0"> 성장 목표 
                
                <v-badge
                icon="mdi-lock"
                color="secondary"
                inline
        ></v-badge>
        </p>
        <h3>지속적인 성장</h3>
        
        <p class="text-caption my-0">  콘텐츠에 광고를 게재하는 옵션을 비롯해 더 많은 수익 창출 방법을 활용하세요.
        </p>
        </v-col>
    </v-row>
    <v-row>
        <v-col>
            <v-card
            outlined
            >
            
            <v-card-title>
                <div class="d-flex justify-space-between align-center" style="width:100%">
                    <span>보기 페이지 광고</span>
                    <v-btn
                        fab
                        small
                        text
                       @click="show = !show"
                    >
                    <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                </v-btn>
                </div>
            </v-card-title>
            
            <v-expand-transition>
            <div v-show="show">
            <div class="d-flex justify-space-between">
                <div>
                <v-card-text>
                    <p>보기 페이지에서 광고와 Grape 수익을 창출하세요.
                    </p>
                    <p>보기 페이지에서는 콘텐츠 앞뒤 또는 콘텐츠 내에 표시되는 광고, 그리고 콘텐츠를 감상하는 Grape 구독자로부터 수익을 창출할 수 있습니다. 
                    </p>
                </v-card-text>
                </div>
                    <div class="d-flex align-center pr-4">
                        <v-avatar
                            size="100"
                            tile
                        >
                        <v-img
                        height="100"
                        width="100"
                        src="https://cdn.vuetifyjs.com/images/cards/cooking.png"
                        ></v-img>
                        </v-avatar>
                    </div>
                </div>
            </div>
            </v-expand-transition>

        </v-card>
        </v-col>
    </v-row>
    <v-row>
        <v-col>
            <h3>자격요건</h3>
            
            <v-subheader class="text-caption">{{ dateString }} 기준의 데이터 입니다.</v-subheader>
           
            <template>
            <v-progress-linear value="0"></v-progress-linear>
            <div class="d-flex justify-space-between mt-1 text-caption">
                <span>구독자 0명</span>
                <span>10,000</span>
            </div>
            </template>
            <div class="text-center text-caption" style="width: 100%;">
                그리고 다음 중 하나를 충족해야 함
            </div>
            <template>
            <v-progress-linear class="mt-3" value="0"></v-progress-linear>
            <div class="d-flex justify-space-between mt-1 text-caption">
                <span style="line-height: 1.2;">
                    공개 콘텐츠 감상 조회 : 0 조회 <br> 
                    최근 365일
                </span>
                <span>1,000,000</span>
            </div>
            </template>
        </v-col>
    </v-row>
    </template>

  </v-container>
</template>

<script>
  export default {
    data: () => ({
        qualified:false,
      show: true,
    }),
    computed:{
        dateString:()=>{
            const date = new Date()
            const year = date.getFullYear()
            const month = date.getMonth() + 1
            const day = date.getDate()
            return `${year}. ${month}. ${day}.`
        }
    }
  }
</script>

<style>
.undeco a{
    text-decoration: none;
    color: #065fd4 !important;
  }
</style>