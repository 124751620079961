<!-- 8-D-9 -->
<template>
    <v-card>
      <v-toolbar
        outlined
        flat
      >
        <v-toolbar-title>설정</v-toolbar-title>
      </v-toolbar>
      <v-tabs 
      vertical 
      >
        <v-tab class="justify-start">
            일반
        </v-tab>
        <v-tab class="justify-start">
            채널
        </v-tab>
        <v-tab class="justify-start">
            권한
        </v-tab>
        <v-tab class="justify-start">
            커뮤니티
        </v-tab>
        <v-tab class="justify-start">
            약관
        </v-tab>

        <v-tab-item>
          <v-card flat>
            <!-- <v-card-title>
            </v-card-title> -->
            <v-card-text>
                <v-container>
    
                    <!-- <h3 class="d-flex align-center"> -->
                        기본 단위
                        <!-- TODO: tooltip -->
                        <v-icon small>mdi-help-circle-outline</v-icon>
                    <!-- </h3> -->
                    <v-select
                    outlined
                    :items="unitItems"
                    :item-text="item => `${item.value} - ${item.locale}`"
                    item-value="value"
                    v-model="unitSelected"
                    style="max-width: 300px;"
                    ></v-select>
                    
                </v-container>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
                <v-tabs
                >
                <v-tab>
                    기본 정보
                </v-tab>
                <v-tab>
                    고급 설정
                </v-tab>
                <v-tab-item>
                    <v-container class="mt-5">
                        <v-select
                        outlined
                        :items="residenceItems"
                        :item-text="item => `${item.locale}`"
                        item-value="value"
                        v-model="residenceSelected"
                        style="max-width: 300px;"
                        label="거주 국가"
                        hide-details
                        ></v-select>
                        <span class="text-caption pl-3">
                            현재 거주중인 국가를 선택하세요. <a href="#">자세히 알아보기</a>

                        </span>
                        
                        <v-text-field
                        class="mt-5"
                        outlined
                        label="키워드"
                        hint="값을 쉼표로 구분하여 입력하세요."
                        persistent-hint
                        placeholder="키워드 추가"
                        persistent-placeholder
                        ></v-text-field>
                    </v-container>
                    
                </v-tab-item>
                <v-tab-item>
                  
                <template>
  
                  <v-container
                  >
                  <p>감상자층</p>
                    <v-radio-group v-model="radioGroup">
                      <v-radio
                        v-for="(rating,i) in ratings"
                        :key="i"
                        :label="rating.label"
                        :value="rating.value"
                      ></v-radio>
                    </v-radio-group>
                  </v-container>
                </template>
              </v-tab-item>

                </v-tabs>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-container>
            
          <v-card flat>
            <v-card-title>
              <div class="d-flex justify-space-between align-center" style="width: 100%;">
                <span>나무의 권한</span>
                <v-btn
                rounded
                >
                    초대
                </v-btn>
              </div>
            </v-card-title>
            <v-card-text>
              <p>
                다른 사용자에게 채널 권한을 부여하면 해당 사용자가 업로드하고 댓글을 달며 기타 공개적인 활동을 할 수 있습니다. 비공개 콘텐츠나 숨겨진 콘텐츠도 볼 수 있습니다. <a href="#">자세히 알아보기</a>
              </p>
  
            </v-card-text>
          </v-card>
          <v-divider></v-divider>
          <v-card flat>
            <v-card-text>
              <template>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">
                          이름
                        </th>
                        <th class="text-left">
                          역할
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                      >
                        <td>
                          <div class="d-flex ma-2 align-center">

                          <!-- TODO : v-for -->
                          <v-avatar
                            size="35"
                          >
                            <img src="https://cdn.vuetifyjs.com/images/john.jpg" alt="alt">
                          </v-avatar>
                          <div class="d-flex flex-column ml-3">
                            <span>나무</span>
                            <span class="text-caption">minsira1427@gmail.com</span>
                          </div>
                        </div>

                        </td>
                        <td>소유자</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </template>
            </v-card-text>
          </v-card>
        </v-container>

        </v-tab-item>
        <v-tab-item>
          
          <v-card flat>
            <v-card-text>
                <v-tabs
                >
                <v-tab>
                    기본 설정
                </v-tab>
                <v-tab-item>
                  
                <template>
  
                  <v-container
                  >
                  <v-row>

                  <v-col cols="12" sm="6">

                    <p>채널의 댓글 
                      <v-icon class="ml-2" small>mdi-help-circle-outline</v-icon>
                    </p>
                    <v-radio-group v-model="comment">
                      
                      <v-radio
                      class="mb-0"
                      label="켜짐"
                      value="on"
                      ></v-radio>
                      <template>
  <div class="text-left">
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          text
          class="pl-1 mb-3"
        >
          <v-icon>mdi-chevron-down</v-icon>
          댓글 검토 · 기본
        </v-btn>
      </template>
      <v-list>
        <v-list-item
        >
          <v-list-item-title>기본</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
                      <v-radio
                      label="꺼짐"
                      value="off"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <p>실시간 채팅의 메시지</p>
                    <v-checkbox label="부적절할 수 있는 채팅 메시지는 검토를 위해 보류" value="value"></v-checkbox>
                  </v-col>
                  </v-row>
                    
                  </v-container>
                </template>
              </v-tab-item>

                </v-tabs>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-container>
            <v-row class="align-center" style="min-height: 240px;">
              <v-col class="text-center">
                모든 서비스 사용에 Grape 서비스 약관이 적용됩니다. <a href="#">자세히 알아보기</a>

              </v-col>
            </v-row>
            
          </v-container>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </template>

<script>
export default {
    data() {
        return {
            unitItems: [
                { value: 'USD', locale: '미국 달러' },
                { value: 'EUR', locale: '유로' },
                { value: 'JPY', locale: '일본 엔' },
                { value: 'GBP', locale: '영국 파운드' },
                { value: 'AUD', locale: '호주 달러' },
                { value: 'CAD', locale: '캐나다 달러' },
                { value: 'CHF', locale: '스위스 프랑' },
                { value: 'CNY', locale: '중국 위안' },
                { value: 'SEK', locale: '스웨덴 크로나' },
                { value: 'NZD', locale: '뉴질랜드 달러' },
                { value: 'KRW', locale: '대한민국 원' },
                // Add more currencies as needed
            ],
            unitSelected: 'KRW',
            residenceItems:[
                { value: 'US', locale: '미국' },
                // { value: 'EU', locale: '유럽 연합' }, // 유로는 여러 국가에서 사용되므로 '유럽 연합'으로 표기
                { value: 'JP', locale: '일본' },
                { value: 'GB', locale: '영국' },
                { value: 'AU', locale: '호주' },
                { value: 'CA', locale: '캐나다' },
                { value: 'CH', locale: '스위스' },
                { value: 'CN', locale: '중국' },
                { value: 'SE', locale: '스웨덴' },
                { value: 'NZ', locale: '뉴질랜드' },
                { value: 'KR', locale: '대한민국' },
                ],
            residenceSelected:'KR',
            ratings:[
              {
                label: '콘텐츠 이용자를 전체 감상가로 정하겠습니다.',
                value: 1
              },
              {
                label: '콘텐츠 이용자를 만13세 이상으로 제한하겠습니다.',
                value: 2
              },
              {
                label: '콘텐츠 이용자를 만19세 이상으로 제한하겠습니다.',
                value: 3
              },
            ],
            comment:'on',
        }
    }
}
</script>

<style scoped>
a{
    text-decoration: none;
    color: #065fd4;
}
</style>